import { all } from "redux-saga/effects";

import authSagas from "./auth/saga";
import commonSagas from "./common/saga";
import masterSagas from "./masters/saga";
import profileSagas from "./profile/saga";
import customerSagas from "./customer/saga";
import quotationSagas from "./quotation/saga";
import invoiceSagas from "./invoice/saga";
import employOnboardingSagas from "./employ_onboarding/saga";
import employAttendanceSagas from "./employeeAttendance/saga";
import projectSagas from "./project/saga";
import ticketSagas from "./ticket/saga";
import recruitSagas from "./recruit/saga";
import roleSagas from "./roles_and_rights/saga";
import userManagementSagas from "./user/saga";
import gstMasterSagas from "./gst_master/saga";
import codeSetupSagas from "./codesetup/saga";
import holidaySagas from "./holidays/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    commonSagas(),
    masterSagas(),
    profileSagas(),
    customerSagas(),
    quotationSagas(),
    invoiceSagas(),
    employOnboardingSagas(),
    employAttendanceSagas(),
    projectSagas(),
    ticketSagas(),
    recruitSagas(),
    userManagementSagas(),
    roleSagas(),
    gstMasterSagas(),
    codeSetupSagas(),
    holidaySagas(),
  ]);
}
