import {
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchInvoiceAsync = async (id) => {

  try {
    const invoiceRef = collection(database, "invoices");

    const q = query(invoiceRef, where("createdBy", "==", String(id)));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
      });
      response = {
        status: 200,
        data: dataArr,
        message: t("message_invoices_get_success"),
      };
    } else {
      response = {
        status: 400,
        message: t("message_invoices_not_found"),
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchInvoicesForReportAsync = async (id, startDate, endDate) => {
  
  try {
    const invoiceRef = collection(database, "invoices");

    const q = query(invoiceRef, where("createdBy", "==", String(id)),orderBy("createdAt","desc"),where("createdAt",">=",startDate),where("createdAt","<=",endDate));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
      });
      response = {
        status: 200,
        data: dataArr,
        message: t("message_invoices_get_success"),
      };
    } else {
      response = {
        status: 400,
        message: t("message_invoices_not_found"),
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const generateInvoiceNoAsync = async (uid) => {
  const response = await generateID(uid, "Invoice");
  return response;
};

export const createInvoiceAsync = async (payload) => {
  try {
    await setDoc(doc(database, "invoices", Number(payload._id).toString()), {
      ...payload,
      createdAt: new Date(),
    });
    response = {
      status: 200,
      message: t("message_invoice_new_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateInvoiceAsync = async (payload, id) => {
  try {
    await setDoc(doc(database, "invoices", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_invoice_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
export const updatePaymentInfoAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "invoices", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_invoice_payment_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
export const cancelInvoiceAsync = async (id) => {
  try {
    await updateDoc(doc(database, "invoices", String(id)), {
      isCancelled: true,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_invoice_cancel_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
export const restoreInvoiceAsync = async (id) => {
  try {
    await updateDoc(doc(database, "invoices", String(id)), {
      isCancelled: false,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_invoice_restore_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchInvoiceDetailAsync = async (id) => {
  try {
    const invoiceRef = collection(database, "invoices");

    const docRef = doc(database, "invoices", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "invoice details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteInvoiceAsync = async (id) => {
  try {
    const docRef = doc(database, "invoices", String(id));
    await deleteDoc(docRef);

    response = {
      status: 200,
      message: "invoice deleted succesfully...",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
