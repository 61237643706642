import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchEmployeeAttendanceAsync = async (uid) => {
    try {
      const employeeAttendanceRef = collection(database, "employeeAttendances");

      const q = query(employeeAttendanceRef, where("createdBy", "==", String(uid)));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
        response = {
          status: 200,
          data: dataArr,
          message: t("message_employeeAttendances_get_success"),
        };
      } else {
        response = {
          status: 400,
          message: t("message_employeeAttendances_not_found"),
        };
      }

      return response;
    } catch (error) {
      let message = generateMessage(error.code);
      response = {
        status: 401,
        data: null,
        message: message || error.message,
      };
      return response;
    }
};

export const generateSalarySlipNoAsync = async (uid) => {
  const response = await generateID(uid, "Salary slip");
  return response;
};

export const fetchAttendanceReportAsync = async (uid,employee, monthAndYear) => {
  console.log(uid, employee, monthAndYear)
  try {
    const employeeAttendanceRef = collection(database, "employeeAttendances");
    let q = null
    if(employee == "all"){
        q = query(employeeAttendanceRef, where("createdBy", "==", String(uid)), where("monthAndYear", "==", String(monthAndYear)));
    }else{
        q = query(employeeAttendanceRef, where("createdBy", "==", String(uid)), where("employee", "==", String(employee)) , where("monthAndYear", "==", String(monthAndYear)));      
    }

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
      });
      response = {
        status: 200,
        data: dataArr,
        message: t("message_attendance_report_get_success"),
      };
    } else {
      response = {
        status: 400,
        message: t("message_attendance_report_not_found"),
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const createEmployeeAttendanceAsync = async (payload) => {
  try {
    const employeeAttendanceRef = collection(database, "employeeAttendances");

    const q = query(employeeAttendanceRef, where("createdBy", "==", String(payload.createdBy)),where("email","==",payload.email),where("startDate","==",payload.startDate));

    const querySnapshot = await getDocs(q);
    if(querySnapshot.empty){    
    await setDoc(doc(database, "employeeAttendances", Number(payload._id).toString()), {
      ...payload,
      createdAt: new Date(),
    });
    await updateDoc(doc(database, "employOnboardings", String(payload.employee)), {
      isDayEnded: payload.isDayEnded,
      isDayStarted: payload.isDayStarted,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_employeeAttendance_new_successful"),
    };
  }else{
    response = {
      status: 400,
      message: `Attendance info has already filled for Date: ${new Date(payload.startDate).toDateString()}`,
    };
  }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateEmployeeAttendanceAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "employeeAttendances", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    await updateDoc(doc(database, "employOnboardings", String(payload.employee)), {
      isDayEnded: payload.isDayEnded,
      isDayStarted: payload.isDayStarted,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_employeeAttendance_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchEmployeeAttendanceDetailAsync = async (id) => {
  try {
    const employeeAttendanceRef = collection(database, "employeeAttendances");

    const docRef = doc(database, "employeeAttendances", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "employeeAttendance details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteEmployeeAttendanceAsync = async (attendanceId,employeeId) => {
  try {
    const docRef = doc(database, "employeeAttendances", String(attendanceId));
    await deleteDoc(docRef);
    await updateDoc(doc(database, "employOnboardings", String(employeeId)), {
      isDayEnded: true,
      isDayStarted: false,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: "employeeAttendance deleted succesfully...",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};