export const SET_LOADER = "SET_LOADER";
export const SET_MESSAGE = "SET_MESSAGE";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_COUNT_RESET = "LOGIN_COUNT_RESET";
export const RESET_PERMISSION = "RESET_PERMISSION";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_USER_FOR_FOGET_PASS = "VERIFY_USER_FOR_FOGET_PASS";
export const VERIFY_USER_FOR_FOGET_PASS_SUCCESS =
  "VERIFY_USER_FOR_FOGET_PASS_SUCCESS";
export const VERIFY_USER_FOR_FOGET_PASS_FAILURE =
  "VERIFY_USER_FOR_FOGET_PASS_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const MASTER_GET_ALL = "MASTER_GET_ALL";
export const MASTER_GET_ALL_SUCCESS = "MASTER_GET_ALL_SUCCESS";
export const MASTER_GET_ALL_FAILURE = "MASTER_GET_ALL_FAILURE";

export const MASTER_LIST = "MASTER_LIST";
export const MASTER_LIST_SUCCESS = "MASTER_LIST_SUCCESS";
export const MASTER_LIST_FAILURE = "MASTER_LIST_FAILURE";

export const CREATE_MASTER = "CREATE_MASTER";
export const CREATE_MASTER_SUCCESS = "CREATE_MASTER_SUCCESS";
export const CREATE_MASTER_FAILURE = "CREATE_MASTER_FAILURE";

export const UPDATE_MASTER = "UPDATE_MASTER";
export const UPDATE_MASTER_SUCCESS = "UPDATE_MASTER_SUCCESS";
export const UPDATE_MASTER_FAILURE = "UPDATE_MASTER_FAILURE";

export const UPDATE_MASTER_STATUS = "UPDATE_MASTER_STATUS";
export const UPDATE_MASTER_STATUS_SUCCESS = "UPDATE_MASTER_STATUS_SUCCESS";
export const UPDATE_MASTER_STATUS_FAILURE = "UPDATE_MASTER_STATUS_FAILURE";

// Resources for Quotation module
export const GENERATE_QUOTATION_ID = "GENERATE_QUOTATION_ID";
export const GENERATE_QUOTATION_ID_SUCCESS = "GENERATE_QUOTATION_ID_SUCCESS";
export const GENERATE_QUOTATION_ID_FAILURE = "GENERATE_QUOTATION_ID_FAILURE";

export const QUOTATION_NEW = "QUOTATION_NEW";
export const QUOTATION_NEW_SUCCESS = "QUOTATION_NEW_SUCCESS";
export const QUOTATION_NEW_FAILURE = "QUOTATION_NEW_FAILURE";

export const QUOTATION_UPDATE = "QUOTATION_UPDATE";
export const QUOTATION_UPDATE_SUCCESS = "QUOTATION_UPDATE_SUCCESS";
export const QUOTATION_UPDATE_FAILURE = "QUOTATION_UPDATE_FAILURE";

export const QUOTATION_LIST = "QUOTATION_LIST";
export const QUOTATION_LIST_SUCCESS = "QUOTATION_LIST_SUCCESS";
export const QUOTATION_LIST_FAILURE = "QUOTATION_LIST_FAILURE";

export const QUOTATION_DETAIL = "QUOTATION_DETAIL";
export const QUOTATION_DETAIL_SUCCESS = "QUOTATION_DETAIL_SUCCESS";
export const QUOTATION_DETAIL_FAILURE = "QUOTATION_DETAIL_FAILURE";

export const QUOTATION_DELETE = "QUOTATION_DELETE";
export const QUOTATION_DELETE_SUCCESS = "QUOTATION_DELETE_SUCCESS";
export const QUOTATION_DELETE_FAILURE = "QUOTATION_DELETE_FAILURE";

// Resources for Invoice module
export const GENERATE_INVOICE_ID = "GENERATE_INVOICE_ID";
export const GENERATE_INVOICE_ID_SUCCESS = "GENERATE_INVOICE_ID_SUCCESS";
export const GENERATE_INVOICE_ID_FAILURE = "GENERATE_INVOICE_ID_FAILURE";

export const INVOICE_NEW = "INVOICE_NEW";
export const INVOICE_NEW_SUCCESS = "INVOICE_NEW_SUCCESS";
export const INVOICE_NEW_FAILURE = "INVOICE_NEW_FAILURE";

export const INVOICE_UPDATE = "INVOICE_UPDATE";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";
export const INVOICE_UPDATE_FAILURE = "INVOICE_UPDATE_FAILURE";

export const INVOICE_PAYMENT_UPDATE = "INVOICE_PAYMENT_UPDATE";
export const INVOICE_PAYMENT_UPDATE_SUCCESS = "INVOICE_PAYMENT_UPDATE_SUCCESS";
export const INVOICE_PAYMENT_UPDATE_FAILURE = "INVOICE_PAYMENT_UPDATE_FAILURE";

export const INVOICE_LIST = "INVOICE_LIST";
export const INVOICE_LIST_SUCCESS = "INVOICE_LIST_SUCCESS";
export const INVOICE_LIST_FAILURE = "INVOICE_LIST_FAILURE";

export const INVOICE_FOR_REPORT_LIST = "INVOICE_FOR_REPORT_LIST";
export const INVOICE_FOR_REPORT_LIST_SUCCESS = "INVOICE_FOR_REPORT_LIST_SUCCESS";
export const INVOICE_FOR_REPORT_LIST_FAILURE = "INVOICE_FOR_REPORT_LIST_FAILURE";

export const INVOICE_DETAIL = "INVOICE_DETAIL";
export const INVOICE_DETAIL_SUCCESS = "INVOICE_DETAIL_SUCCESS";
export const INVOICE_DETAIL_FAILURE = "INVOICE_DETAIL_FAILURE";

export const INVOICE_DELETE = "INVOICE_DELETE";
export const INVOICE_DELETE_SUCCESS = "INVOICE_DELETE_SUCCESS";
export const INVOICE_DELETE_FAILURE = "INVOICE_DELETE_FAILURE";

export const INVOICE_CANCEL = "INVOICE_CANCEL";
export const INVOICE_CANCEL_SUCCESS = "INVOICE_CANCEL_SUCCESS";
export const INVOICE_CANCEL_FAILURE = "INVOICE_CANCEL_FAILURE";

export const INVOICE_RESTORE = "INVOICE_RESTORE";
export const INVOICE_RESTORE_SUCCESS = "INVOICE_RESTORE_SUCCESS";
export const INVOICE_RESTORE_FAILURE = "INVOICE_RESTORE_FAILURE";

/** Employ Onboarding module related types */
export const EMPLOY_ONBOARDING_LIST = "EMPLOY_ONBOARDING_LIST";
export const EMPLOY_ONBOARDING_LIST_SUCCESS = "EMPLOY_ONBOARDING_LIST_SUCCESS";
export const EMPLOY_ONBOARDING_LIST_FAILURE = "EMPLOY_ONBOARDING_LIST_FAILURE";

export const EMPLOY_ONBOARDING_NEW = "EMPLOY_ONBOARDING_NEW";
export const EMPLOY_ONBOARDING_NEW_SUCCESS = "EMPLOY_ONBOARDING_NEW_SUCCESS";
export const EMPLOY_ONBOARDING_NEW_FAILURE = "EMPLOY_ONBOARDING_NEW_FAILURE";

export const EMPLOY_ONBOARDING_UPDATE = "EMPLOY_ONBOARDING_UPDATE";
export const EMPLOY_ONBOARDING_UPDATE_SUCCESS =
  "EMPLOY_ONBOARDING_UPDATE_SUCCESS";
export const EMPLOY_ONBOARDING_UPDATE_FAILURE =
  "EMPLOY_ONBOARDING_UPDATE_FAILURE";

export const EMPLOY_ONBOARDING_DETAIL = "EMPLOY_ONBOARDING_DETAIL";
export const EMPLOY_ONBOARDING_DETAIL_SUCCESS =
  "EMPLOY_ONBOARDING_DETAIL_SUCCESS";
export const EMPLOY_ONBOARDING_DETAIL_FAILURE =
  "EMPLOY_ONBOARDING_DETAIL_FAILURE";

export const EMPLOY_ONBOARDING_DELETE = "EMPLOY_ONBOARDING_DELETE";
export const EMPLOY_ONBOARDING_DELETE_SUCCESS =
  "EMPLOY_ONBOARDING_DELETE_SUCCESS";
export const EMPLOY_ONBOARDING_DELETE_FAILURE =
  "EMPLOY_ONBOARDING_DELETE_FAILURE";

export const GENERATE_EMPLOY_ONBOARDING_ID = "GENERATE_EMPLOY_ONBOARDING_ID";
export const GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS = "GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS";
export const GENERATE_EMPLOY_ONBOARDING_ID_FAILURE = "GENERATE_EMPLOY_ONBOARDING_ID_FAILURE";

// Resources for Employee attendance module

export const EMPLOYEE_ATTENDANCE_NEW = "EMPLOYEE_ATTENDANCE_NEW";
export const EMPLOYEE_ATTENDANCE_NEW_SUCCESS = "EMPLOYEE_ATTENDANCE_NEW_SUCCESS";
export const EMPLOYEE_ATTENDANCE_NEW_FAILURE = "EMPLOYEE_ATTENDANCE_NEW_FAILURE";

export const EMPLOYEE_ATTENDANCE_UPDATE = "EMPLOYEE_ATTENDANCE_UPDATE";
export const EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS = "EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_UPDATE_FAILURE = "EMPLOYEE_ATTENDANCE_UPDATE_FAILURE";

export const EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE = "EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE";
export const EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_SUCCESS = "EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_FAILURE = "EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_FAILURE";

export const EMPLOYEE_ATTENDANCE_LIST = "EMPLOYEE_ATTENDANCE_LIST";
export const EMPLOYEE_ATTENDANCE_LIST_SUCCESS = "EMPLOYEE_ATTENDANCE_LIST_SUCCESS";
export const EMPLOYEE_ATTENDANCE_LIST_FAILURE = "EMPLOYEE_ATTENDANCE_LIST_FAILURE";

export const EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST = "EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST";
export const EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_SUCCESS = "EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_SUCCESS";
export const EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_FAILURE = "EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_FAILURE";

export const EMPLOYEE_ATTENDANCE_DETAIL = "EMPLOYEE_ATTENDANCE_DETAIL";
export const EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS = "EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS";
export const EMPLOYEE_ATTENDANCE_DETAIL_FAILURE = "EMPLOYEE_ATTENDANCE_DETAIL_FAILURE";

export const EMPLOYEE_ATTENDANCE_DELETE = "EMPLOYEE_ATTENDANCE_DELETE";
export const EMPLOYEE_ATTENDANCE_DELETE_SUCCESS = "EMPLOYEE_ATTENDANCE_DELETE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_DELETE_FAILURE = "EMPLOYEE_ATTENDANCE_DELETE_FAILURE";

export const EMPLOYEE_ATTENDANCE_RESTORE = "EMPLOYEE_ATTENDANCE_RESTORE";
export const EMPLOYEE_ATTENDANCE_RESTORE_SUCCESS = "EMPLOYEE_ATTENDANCE_RESTORE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_RESTORE_FAILURE = "EMPLOYEE_ATTENDANCE_RESTORE_FAILURE";

export const ATTENDANCE_REPORT = "ATTENDANCE_REPORT";
export const ATTENDANCE_REPORT_SUCCESS = "ATTENDANCE_REPORT_SUCCESS";
export const ATTENDANCE_REPORT_FAILURE = "ATTENDANCE_REPORT_FAILURE";

export const GENERATE_SALARY_SLIP_NO = "GENERATE_SALARY_SLIP_NO";
export const GENERATE_SALARY_SLIP_NO_SUCCESS = "GENERATE_SALARY_SLIP_NO_SUCCESS";
export const GENERATE_SALARY_SLIP_NO_FAILURE = "GENERATE_SALARY_SLIP_NO_FAILURE";

// Resources for Project module
export const GENERATE_PROJECT_ID = "GENERATE_PROJECT_ID";
export const GENERATE_PROJECT_ID_SUCCESS = "GENERATE_PROJECT_ID_SUCCESS";
export const GENERATE_PROJECT_ID_FAILURE = "GENERATE_PROJECT_ID_FAILURE";

export const PROJECT_NEW = "PROJECT_NEW";
export const PROJECT_NEW_SUCCESS = "PROJECT_NEW_SUCCESS";
export const PROJECT_NEW_FAILURE = "PROJECT_NEW_FAILURE";

export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_FAILURE = "PROJECT_UPDATE_FAILURE";

export const PROJECT_PAYMENT_UPDATE = "PROJECT_PAYMENT_UPDATE";
export const PROJECT_PAYMENT_UPDATE_SUCCESS = "PROJECT_PAYMENT_UPDATE_SUCCESS";
export const PROJECT_PAYMENT_UPDATE_FAILURE = "PROJECT_PAYMENT_UPDATE_FAILURE";

export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_FAILURE = "PROJECT_LIST_FAILURE";

export const PROJECT_DETAIL = "PROJECT_DETAIL";
export const PROJECT_DETAIL_SUCCESS = "PROJECT_DETAIL_SUCCESS";
export const PROJECT_DETAIL_FAILURE = "PROJECT_DETAIL_FAILURE";

export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_FAILURE = "PROJECT_DELETE_FAILURE";

export const PROJECT_CANCEL = "PROJECT_CANCEL";
export const PROJECT_CANCEL_SUCCESS = "PROJECT_CANCEL_SUCCESS";
export const PROJECT_CANCEL_FAILURE = "PROJECT_CANCEL_FAILURE";

// Resources for Tickets module
export const GENERATE_TICKET_ID = "GENERATE_TICKET_ID";
export const GENERATE_TICKET_ID_SUCCESS = "GENERATE_TICKET_ID_SUCCESS";
export const GENERATE_TICKET_ID_FAILURE = "GENERATE_TICKET_ID_FAILURE";

export const TICKET_NEW = "TICKET_NEW";
export const TICKET_NEW_SUCCESS = "TICKET_NEW_SUCCESS";
export const TICKET_NEW_FAILURE = "TICKET_NEW_FAILURE";

export const TICKET_UPDATE = "TICKET_UPDATE";
export const TICKET_UPDATE_SUCCESS = "TICKET_UPDATE_SUCCESS";
export const TICKET_UPDATE_FAILURE = "TICKET_UPDATE_FAILURE";

export const TICKET_LIST = "TICKET_LIST";
export const TICKET_LIST_SUCCESS = "TICKET_LIST_SUCCESS";
export const TICKET_LIST_FAILURE = "TICKET_LIST_FAILURE";

export const TICKET_DETAIL = "TICKET_DETAIL";
export const TICKET_DETAIL_SUCCESS = "TICKET_DETAIL_SUCCESS";
export const TICKET_DETAIL_FAILURE = "TICKET_DETAIL_FAILURE";

export const TICKET_DELETE = "TICKET_DELETE";
export const TICKET_DELETE_SUCCESS = "TICKET_DELETE_SUCCESS";
export const TICKET_DELETE_FAILURE = "TICKET_DELETE_FAILURE";

// Resources for Recruit module
export const RECRUIT_NEW = "RECRUIT_NEW";
export const RECRUIT_NEW_SUCCESS = "RECRUIT_NEW_SUCCESS";
export const RECRUIT_NEW_FAILURE = "RECRUIT_NEW_FAILURE";

export const RECRUIT_UPDATE = "RECRUIT_UPDATE";
export const RECRUIT_UPDATE_SUCCESS = "RECRUIT_UPDATE_SUCCESS";
export const RECRUIT_UPDATE_FAILURE = "RECRUIT_UPDATE_FAILURE";

export const RECRUIT_LIST = "RECRUIT_LIST";
export const RECRUIT_LIST_SUCCESS = "RECRUIT_LIST_SUCCESS";
export const RECRUIT_LIST_FAILURE = "RECRUIT_LIST_FAILURE";

export const RECRUIT_DETAIL = "RECRUIT_DETAIL";
export const RECRUIT_DETAIL_SUCCESS = "RECRUIT_DETAIL_SUCCESS";
export const RECRUIT_DETAIL_FAILURE = "RECRUIT_DETAIL_FAILURE";

export const RECRUIT_DELETE = "RECRUIT_DELETE";
export const RECRUIT_DELETE_SUCCESS = "RECRUIT_DELETE_SUCCESS";
export const RECRUIT_DELETE_FAILURE = "RECRUIT_DELETE_FAILURE";

/** Customer module related types */
export const CUSTOMER_NEW = "CUSTOMER_NEW";
export const CUSTOMER_NEW_SUCCESS = "CUSTOMER_NEW_SUCCESS";
export const CUSTOMER_NEW_FAILURE = "CUSTOMER_NEW_FAILURE";

export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAILURE = "CUSTOMER_UPDATE_FAILURE";

export const CUSTOMER_LIST = "CUSTOMER_LIST";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAILURE = "CUSTOMER_LIST_FAILURE";

export const CUSTOMER_DETAIL = "CUSTOMER_DETAIL";
export const CUSTOMER_DETAIL_SUCCESS = "CUSTOMER_DETAIL_SUCCESS";
export const CUSTOMER_DETAIL_FAILURE = "CUSTOMER_DETAIL_FAILURE";

export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_DELETE_SUCCESS = "CUSTOMER_DELETE_SUCCESS";
export const CUSTOMER_DELETE_FAILURE = "CUSTOMER_DELETE_FAILURE";

/** Roles and rights module related types */
export const ROLES_NEW = "ROLES_NEW";
export const ROLES_NEW_SUCCESS = "ROLES_NEW_SUCCESS";
export const ROLES_NEW_FAILURE = "ROLES_NEW_FAILURE";

export const ROLES_UPDATE = "ROLES_UPDATE";
export const ROLES_UPDATE_SUCCESS = "ROLES_UPDATE_SUCCESS";
export const ROLES_UPDATE_FAILURE = "ROLES_UPDATE_FAILURE";

export const ROLES_LIST = "ROLES_LIST";
export const ROLES_LIST_SUCCESS = "ROLES_LIST_SUCCESS";
export const ROLES_LIST_FAILURE = "ROLES_LIST_FAILURE";

export const ROLES_DETAIL = "ROLES_DETAIL";
export const ROLES_DETAIL_SUCCESS = "ROLES_DETAIL_SUCCESS";
export const ROLES_DETAIL_FAILURE = "ROLES_DETAIL_FAILURE";

export const ROLES_DELETE = "ROLES_DELETE";
export const ROLES_DELETE_SUCCESS = "ROLES_DELETE_SUCCESS";
export const ROLES_DELETE_FAILURE = "ROLES_DELETE_FAILURE";

/** User module related types */
export const USER_LIST = "USER_LIST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const USER_NEW = "USER_NEW";
export const USER_NEW_SUCCESS = "USER_NEW_SUCCESS";
export const USER_NEW_FAILURE = "USER_NEW_FAILURE";

export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export const USER_DETAIL = "USER_DETAIL";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE";

export const USER_DELETE = "USER_DELETE";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

/** GST master module related types */
export const GST_MASTER_LIST = "GST_MASTER_LIST";
export const GST_MASTER_LIST_SUCCESS = "GST_MASTER_LIST_SUCCESS";
export const GST_MASTER_LIST_FAILURE = "GST_MASTER_LIST_FAILURE";

export const GST_MASTER_NEW = "GST_MASTER_NEW";
export const GST_MASTER_NEW_SUCCESS = "GST_MASTER_NEW_SUCCESS";
export const GST_MASTER_NEW_FAILURE = "GST_MASTER_NEW_FAILURE";

export const GST_MASTER_UPDATE = "GST_MASTER_UPDATE";
export const GST_MASTER_UPDATE_SUCCESS = "GST_MASTER_UPDATE_SUCCESS";
export const GST_MASTER_UPDATE_FAILURE = "GST_MASTER_UPDATE_FAILURE";

export const GST_MASTER_UPDATE_STATUS = "GST_MASTER_UPDATE_STATUS";
export const GST_MASTER_UPDATE_STATUS_SUCCESS =
  "GST_MASTER_UPDATE_STATUS_SUCCESS";
export const GST_MASTER_UPDATE_STATUS_FAILURE =
  "GST_MASTER_UPDATE_STATUS_FAILURE";

export const GST_MASTER_DETAIL = "GST_MASTER_DETAIL";
export const GST_MASTER_DETAIL_SUCCESS = "GST_MASTER_DETAIL_SUCCESS";
export const GST_MASTER_DETAIL_FAILURE = "GST_MASTER_DETAIL_FAILURE";

export const GST_MASTER_DELETE = "GST_MASTER_DELETE";
export const GST_MASTER_DELETE_SUCCESS = "GST_MASTER_DELETE_SUCCESS";
export const GST_MASTER_DELETE_FAILURE = "GST_MASTER_DELETE_FAILURE";

export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Resources for Code Setup module
export const CODE_SETUP_NEW = "CODE_SETUP_NEW";
export const CODE_SETUP_NEW_SUCCESS = "CODE_SETUP_NEW_SUCCESS";
export const CODE_SETUP_NEW_FAILURE = "CODE_SETUP_NEW_FAILURE";

export const CODE_SETUP_UPDATE = "CODE_SETUP_UPDATE";
export const CODE_SETUP_UPDATE_SUCCESS = "CODE_SETUP_UPDATE_SUCCESS";
export const CODE_SETUP_UPDATE_FAILURE = "CODE_SETUP_UPDATE_FAILURE";

export const CODE_SETUP_LIST = "CODE_SETUP_LIST";
export const CODE_SETUP_LIST_SUCCESS = "CODE_SETUP_LIST_SUCCESS";
export const CODE_SETUP_LIST_FAILURE = "CODE_SETUP_LIST_FAILURE";

export const CODE_SETUP_DETAIL = "CODE_SETUP_DETAIL";
export const CODE_SETUP_DETAIL_SUCCESS = "CODE_SETUP_DETAIL_SUCCESS";
export const CODE_SETUP_DETAIL_FAILURE = "CODE_SETUP_DETAIL_FAILURE";

export const CODE_SETUP_DELETE = "CODE_SETUP_DELETE";
export const CODE_SETUP_DELETE_SUCCESS = "CODE_SETUP_DELETE_SUCCESS";
export const CODE_SETUP_DELETE_FAILURE = "CODE_SETUP_DELETE_FAILURE";

/** Profile module related types */
export const PROFILE_GET = "PROFILE_GET";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAILURE = "PROFILE_GET_FAILURE";

export const PROFILE_DETAIL = "PROFILE_DETAIL";
export const PROFILE_DETAIL_SUCCESS = "PROFILE_DETAIL_SUCCESS";
export const PROFILE_DETAIL_FAILURE = "PROFILE_DETAIL_FAILURE";

export const PROFILE_BANK_DETAIL = "PROFILE_BANK_DETAIL";
export const PROFILE_BANK_DETAIL_SUCCESS = "PROFILE_BANK_DETAIL_SUCCESS";
export const PROFILE_BANK_DETAIL_FAILURE = "PROFILE_BANK_DETAIL_FAILURE";

/** Holiday setup module related types */
export const HOLIDAYS_LIST = "HOLIDAYS_LIST";
export const HOLIDAYS_LIST_SUCCESS = "HOLIDAYS_LIST_SUCCESS";
export const HOLIDAYS_LIST_FAILURE = "HOLIDAYS_LIST_FAILURE";

export const HOLIDAYS_NEW = "HOLIDAYS_NEW";
export const HOLIDAYS_NEW_SUCCESS = "HOLIDAYS_NEW_SUCCESS";
export const HOLIDAYS_NEW_FAILURE = "HOLIDAYS_NEW_FAILURE";

export const HOLIDAYS_UPDATE = "HOLIDAYS_UPDATE";
export const HOLIDAYS_UPDATE_SUCCESS =
  "HOLIDAYS_UPDATE_SUCCESS";
export const HOLIDAYS_UPDATE_FAILURE =
  "HOLIDAYS_UPDATE_FAILURE";

export const HOLIDAYS_DETAIL = "HOLIDAYS_DETAIL";
export const HOLIDAYS_DETAIL_SUCCESS =
  "HOLIDAYS_DETAIL_SUCCESS";
export const HOLIDAYS_DETAIL_FAILURE =
  "HOLIDAYS_DETAIL_FAILURE";

export const HOLIDAYS_DELETE = "HOLIDAYS_DELETE";
export const HOLIDAYS_DELETE_SUCCESS =
  "HOLIDAYS_DELETE_SUCCESS";
export const HOLIDAYS_DELETE_FAILURE =
  "HOLIDAYS_DELETE_FAILURE";