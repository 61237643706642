import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cogoToast from "cogo-toast";
import { hideMessage } from "../redux/lem/action";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import 'bootstrap/dist/css/bootstrap.min.css';
const Loader = () => {
  const state = useSelector((state) => state.Lem);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.message && state.message !== null && !state.loading) {
      const { type, text, duration, position } = state.message;
      switch (type) {
        case "error":
          cogoToast
            .error(text, {
              position: position,
              hideAfter: duration / 1000,
            })
            .then(() => {
              dispatch(hideMessage());
            });
          break;
        default:
          cogoToast
            .info(text, {
              position: position,
              hideAfter: duration / 1000,
            })
            .then(() => {
              dispatch(hideMessage());
            });
          break;
      }
    }
  }, [dispatch, state.message]);

  return (
    <>
      {state.loading ? (
        <div
          className="modal d-flex"
          style={{
            width: "100%",
            // height: "100%",
            backgroundColor: "white",
            opacity: 0.96,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            // height={200}
            width={250}
            options={{
              autoplay: true,
              loop: true,
              animationData: require("../assets/lottie/lottie1.json"),
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default Loader;
