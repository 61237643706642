import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchCodeSetupAsync = async (uid) => {
  try {
    const codeSetupRef = collection(database, "codeSetups");

    const q = query(codeSetupRef, where("createdBy", "==", String(uid)));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
      });
      response = {
        status: 200,
        data: dataArr,
      };
    } else {
      response = {
        status: 400,
        message: t("message_codeSetups_not_found"),
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const createCodeSetupAsync = async (payload) => {
  try {
    const codeSetupRef = collection(database, "codeSetups");

    const q = query(
      codeSetupRef,
      where("createdBy", "==", payload?.createdBy),
      where("moduleSlug", "==", payload.moduleSlug)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      await setDoc(
        doc(database, "codeSetups", Number(payload._id).toString()),
        {
          ...payload,
          createdAt: new Date(),
        }
      );
      response = {
        status: 200,
        message: t("message_codeSetup_new_successful"),
      };
    } else {
      response = {
        status: 401,
        message: `Code setup already exists for ${payload.moduleSlug} module`,
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateCodeSetupAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "codeSetups", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_codeSetup_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchCodeSetupDetailAsync = async (id) => {
  try {
    const codeSetupRef = collection(database, "codeSetups");

    const docRef = doc(database, "codeSetups", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "codeSetup details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteCodeSetupAsync = async (id) => {
  try {
    const docRef = doc(database, "codeSetups", String(id));
    await deleteDoc(docRef);

    response = {
      status: 200,
      message: "codeSetup deleted succesfully...",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};