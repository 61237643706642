import { all, call, put, fork, takeLatest } from "redux-saga/effects";

import {
  PROFILE_GET,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  PROFILE_DETAIL,
  PROFILE_DETAIL_SUCCESS,
  PROFILE_DETAIL_FAILURE,
  PROFILE_BANK_DETAIL,
  PROFILE_BANK_DETAIL_SUCCESS,
  PROFILE_BANK_DETAIL_FAILURE,
} from "../types";
import {
  addProfileBankDetailsAsync,
  addProfileDetailsAsync,
  fetchProfilesAsync,
} from "../../services/profile.service";
import { hideLoader, showMessage } from "../lem/action";
import { uploadFile } from "../common/saga";

export function* watchFetchProfiles() {
  yield takeLatest(PROFILE_GET, fetchProfileInfo);
}

export function* watchAddProfileDetails() {
  yield takeLatest(PROFILE_DETAIL, addProfileDetails);
}

export function* watchAddProfileBankDetails() {
  yield takeLatest(PROFILE_BANK_DETAIL, addProfileBankDetails);
}

function* fetchProfileInfo({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchProfilesAsync, String(uid));
    hideLoader();
    if (response.status === 200) {
      yield put({
        type: PROFILE_GET_SUCCESS,
        payload: { data: response.data },
      });
    } else {
      yield put({ type: PROFILE_GET_FAILURE });
      // yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROFILE_GET_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addProfileDetails({ payload }) { 
  try {
  const { data, logoFile, signatureFile} = payload;
  let companyName = data.companyName.trim().replace(" ","_");
  let responseLogoFileUpload = null;
  let responseSignatureFileUpload = null;

  if(logoFile.file){
    responseLogoFileUpload = yield call(uploadFile,companyName,"profile","","",logoFile.file)
  }
  if(signatureFile.file){
    responseSignatureFileUpload = yield call(uploadFile,companyName,"profile","","",signatureFile.file)
  }

  //conditionlly set data url of logofile or signaturefile
  if(responseLogoFileUpload !== null){
    data.logo = responseLogoFileUpload.data
  }
  if(responseSignatureFileUpload !== null){
    data.signature = responseSignatureFileUpload.data
  }

    const response = yield call(addProfileDetailsAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: PROFILE_DETAIL_SUCCESS,
        // payload: { profile: response.data },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: PROFILE_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROFILE_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}
function* addProfileBankDetails({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(addProfileBankDetailsAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: PROFILE_BANK_DETAIL_SUCCESS,
        payload: { bankDetails: response.data?.bankDetails },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: PROFILE_BANK_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROFILE_BANK_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([fork(watchFetchProfiles), fork(watchAddProfileDetails),fork(watchAddProfileBankDetails)]);
}
