import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchEmployOnboardingAsync = async (uid) => {

    try {
      const employOnboardingRef = collection(database, "employOnboardings");

      const q = query(
        employOnboardingRef,
        where("createdBy", "==", String(uid))
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
        response = {
          status: 200,
          data: dataArr,
          message: t("message_employOnboardings_get_success"),
        };
      } else {
        response = {
          status: 400,
          message: t("message_employOnboardings_not_found"),
        };
      }

      return response;
    } catch (error) {
      let message = generateMessage(error.code);
      response = {
        status: 401,
        data: null,
        message: message || error.message,
      };
      return response;
    }

};

export const generateEmployOnboardingNoAsync = async (uid) => {
  const response = await generateID(uid, "Employee");
  return response;
};

export const createEmployOnboardingAsync = async (payload) => {
  try {
    const employRef = collection(database, "employOnboardings");

    const q = query(
      employRef,
      where("createdBy", "==", payload?.createdBy),
      where("officialEmailId", "==", payload.officialEmailId)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      await setDoc(
        doc(database, "employOnboardings", Number(payload._id).toString()),
        {
          ...payload,
          isOnBoarded: true,
          createdAt: new Date(),
        }
      );
      await updateDoc(
        doc(database, "recruits", String(payload.applicant._id)),
        {
          isOnBoarded: true,
          lastModified: new Date(),
        }
      );
      response = {
        status: 200,
        message: t("message_employOnboarding_new_successful"),
      };
    } else {
      response = {
        status: 401,
        message: t("message_official_email_exists"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateEmployOnboardingAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "employOnboardings", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_employOnboarding_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchEmployOnboardingDetailAsync = async (id) => {
  try {
    const docRef = doc(database, "employOnboardings", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "employOnboarding details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteEmployOnboardingAsync = async (id, recruitId) => {
  try {
    const docRef = doc(database, "employOnboardings", String(id));
    await deleteDoc(docRef);

    await updateDoc(doc(database, "recruits", String(recruitId)), {
      isOnBoarded: false,
      lastModified: new Date(),
    });

    response = {
      status: 200,
      message: "employOnboarding deleted succesfully...",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
