import {
  MASTER_LIST,
  MASTER_LIST_SUCCESS,
  CREATE_MASTER_SUCCESS,
  UPDATE_MASTER_SUCCESS,
  UPDATE_MASTER_STATUS_SUCCESS,
  UPDATE_MASTER_STATUS,
  UPDATE_MASTER,
} from "../types";
import { CURRENCY_SLUG, DESIGNATION_SLUG, EMPLOYMENT_STATUS_SLUG, JOB_TYPE_SLUG, RECRUITMENT_STATUS_SLUG } from "../../config/constants";

const initialState = {
  jobTypeList: [],
  recruitmentStatusList: [],
  employmentStatusList: [],
  designationList: [],
  currencyList: [],
  createdOrUpdated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case MASTER_LIST:
    case UPDATE_MASTER:
    case UPDATE_MASTER_STATUS:
      return { ...state, createdOrUpdated: false };

    case MASTER_LIST_SUCCESS: {
      const { slug, data } = action.payload;

      if (slug === JOB_TYPE_SLUG) {
        return { ...state, jobTypeList: data };
      }else if (slug === RECRUITMENT_STATUS_SLUG) {
        return { ...state, recruitmentStatusList: data };
      } else if (slug === EMPLOYMENT_STATUS_SLUG) {
        return { ...state, employmentStatusList: data };
      } else if (slug === DESIGNATION_SLUG) {
        return { ...state, designationList: data };
      } else if (slug === CURRENCY_SLUG) {
        return { ...state, currencyList: data };
      }
      return { ...state };
    }

    case CREATE_MASTER_SUCCESS:
    case UPDATE_MASTER_SUCCESS: {
      return { ...state, createdOrUpdated: true };
    }

    case UPDATE_MASTER_STATUS_SUCCESS: {
      return { ...state, createdOrUpdated: true };
    }

    default:
      return { ...state };
  }
}
