import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchMasterListAsync = async (slug, id) => {

    try {
      const masterOptionRef = collection(database, `${slug}`);

      const q = query(masterOptionRef, where("createdBy", "==", id));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
        response = {
          status: 200,
          data: dataArr,
        };
      } else {
        response = {
          status: 400,
          message: t("message_master_option_not_found"),
        };
      }

      return response;
    } catch (error) {
      let message = generateMessage(error.code);
      response = {
        status: 401,
        data: null,
        message: message || error.message,
      };
      return response;
    }
  
};

export const createMasterOptionAsync = async (masterId, masterName, data) => {
  try {
    const masterOptionRef = collection(database, `${masterName}`);
    let name = data.name.trim().toLowerCase();
    const q = query(
      masterOptionRef,
      where("createdBy", "==", data?.createdBy),
      where("name", "==", name)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      await setDoc(doc(database, `${masterName}`, data._id), {
        ...data,
        createdAt: new Date(),
      });
      response = {
        status: 200,
        message: t("message_master_option_create_successful"),
      };
    } else {
      response = {
        status: 401,
        message: t("message_master_option_exists"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateMasterOptionAsync = async (slug, payload, id) => {
  try {
    await updateDoc(doc(database, String(slug), id), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_master_option_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
