import {
  GENERATE_TICKET_ID_SUCCESS,
  TICKET_DELETE_SUCCESS,
  TICKET_DETAIL,
  TICKET_DETAIL_SUCCESS,
  TICKET_LIST,
  TICKET_LIST_FAILURE,
  TICKET_LIST_SUCCESS,
  TICKET_NEW,
  TICKET_NEW_SUCCESS,
  TICKET_UPDATE,
  TICKET_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  tickets: [],
  ticketID: "",
  ticketDetail: null,
  createOrUpdated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TICKET_LIST:
    case TICKET_NEW:
    case TICKET_UPDATE:
    case TICKET_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        ticketDetail: null,
        ticketID: "",
      };

    case TICKET_NEW_SUCCESS:
    case TICKET_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case TICKET_DETAIL_SUCCESS: {
      return { ...state, ticketDetail: payload.ticket };
    }
    case GENERATE_TICKET_ID_SUCCESS: {
      return { ...state, ticketID: payload.ticketID };
    }

    case TICKET_LIST_SUCCESS:
      return { ...state, tickets: payload.tickets };
    case TICKET_LIST_FAILURE:
      return { ...state };

    case TICKET_DELETE_SUCCESS:
      return {
        ...state,
        tickets: state.tickets.filter((item) => item._id !== payload.id),
      };

    default:
      return { ...state };
  }
}
