class TokenService {
  // This function use to get the local refresh token.
  getLocalRefreshToken() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    return user?.accessToken;
  }

  updateLocalAccessToken(accessToken, refreshToken) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      sessionStorage.setItem(
        "user",
        JSON.stringify({ accessToken: accessToken, refreshToken: refreshToken })
      );
    }
  }
  getUser() {
    return JSON.parse(sessionStorage.getItem("user"));
  }

  setUser(user) {
    sessionStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    sessionStorage.removeItem("user");
  }
}

export default new TokenService();
