import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  createCodeSetupAsync,
  deleteCodeSetupAsync,
  fetchCodeSetupAsync,
  fetchCodeSetupDetailAsync,
  updateCodeSetupAsync,
} from "../../services/codeSetup.service";
import { hideLoader, showMessage } from "../lem/action";
import {
  CODE_SETUP_DELETE,
  CODE_SETUP_DELETE_FAILURE,
  CODE_SETUP_DELETE_SUCCESS,
  CODE_SETUP_DETAIL,
  CODE_SETUP_DETAIL_FAILURE,
  CODE_SETUP_DETAIL_SUCCESS,
  CODE_SETUP_LIST,
  CODE_SETUP_LIST_FAILURE,
  CODE_SETUP_LIST_SUCCESS,
  CODE_SETUP_NEW,
  CODE_SETUP_NEW_FAILURE,
  CODE_SETUP_NEW_SUCCESS,
  CODE_SETUP_UPDATE,
  CODE_SETUP_UPDATE_FAILURE,
  CODE_SETUP_UPDATE_SUCCESS,
} from "../types";

export function* watchFetchCodeSetupList() {
  yield takeLatest(CODE_SETUP_LIST, fetchCodeSetupList);
}

export function* watchCreateCodeSetup() {
  yield takeLatest(CODE_SETUP_NEW, createNewCodeSetup);
}

export function* watchUpdateCodeSetup() {
  yield takeLatest(CODE_SETUP_UPDATE, updateCodeSetup);
}

export function* watchGetDetailCodeSetup() {
  yield takeLatest(CODE_SETUP_DETAIL, fetchCodeSetupDetail);
}

export function* watchDeleteCodeSetup() {
  yield takeLatest(CODE_SETUP_DELETE, deleteCodeSetup);
}

function* fetchCodeSetupList({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchCodeSetupAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: CODE_SETUP_LIST_SUCCESS,
        payload: { codeSetups: response.data },
      });
    } else {
      yield put({ type: CODE_SETUP_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewCodeSetup({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createCodeSetupAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: CODE_SETUP_NEW_SUCCESS,
      });
    } else {
      yield put({ type: CODE_SETUP_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateCodeSetup({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateCodeSetupAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: CODE_SETUP_UPDATE_SUCCESS,
      });
    } else {
      yield put({ type: CODE_SETUP_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchCodeSetupDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchCodeSetupDetailAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: CODE_SETUP_DETAIL_SUCCESS,
        payload: { codeSetup: response.data },
      });
    } else {
      yield put({ type: CODE_SETUP_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteCodeSetup({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteCodeSetupAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: CODE_SETUP_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: CODE_SETUP_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCodeSetupList),
    fork(watchCreateCodeSetup),
    fork(watchUpdateCodeSetup),
    fork(watchGetDetailCodeSetup),
    fork(watchDeleteCodeSetup),
  ]);
}
