import {
  CODE_SETUP_DELETE,
  CODE_SETUP_DELETE_SUCCESS,
  CODE_SETUP_DETAIL,
  CODE_SETUP_DETAIL_SUCCESS,
  CODE_SETUP_LIST,
  CODE_SETUP_LIST_SUCCESS,
  CODE_SETUP_NEW,
  CODE_SETUP_NEW_SUCCESS,
  CODE_SETUP_UPDATE,
  CODE_SETUP_UPDATE_SUCCESS,
} from "../types";

const initialState = {
  codeSetups: [],
  codeSetupDetail: null,
  createOrUpdated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CODE_SETUP_LIST:
    case CODE_SETUP_NEW:
    case CODE_SETUP_DETAIL:
    case CODE_SETUP_UPDATE:
    case CODE_SETUP_DELETE:
      return { ...state, createOrUpdated: false, codeSetupDetail: null };

    case CODE_SETUP_LIST_SUCCESS:
      return { ...state, codeSetups: payload.codeSetups };

    case CODE_SETUP_DETAIL_SUCCESS:
      return { ...state, codeSetupDetail: payload.codeSetup };

    case CODE_SETUP_DELETE_SUCCESS: {
      const items = state.codeSetups.filter((item) => item._id !== payload.id);
      return { ...state, codeSetups: items };
    }

    case CODE_SETUP_NEW_SUCCESS:
    case CODE_SETUP_UPDATE_SUCCESS:
      return { ...state, createOrUpdated: true };

    default:
      return { ...state };
  }
}
