import {
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DETAIL,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_LIST,
  CUSTOMER_LIST_FAILURE,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_NEW,
  CUSTOMER_NEW_SUCCESS,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  customers: [],
  customerDetail: null,
  createOrUpdated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_LIST:
    case CUSTOMER_NEW:
    case CUSTOMER_UPDATE:
    case CUSTOMER_DETAIL:
      return { ...state, createOrUpdated: false, customerDetail: null };

    case CUSTOMER_NEW_SUCCESS:
    case CUSTOMER_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case CUSTOMER_DETAIL_SUCCESS: {
      return { ...state, customerDetail: payload.customer };
    }

    case CUSTOMER_LIST_SUCCESS:
      return { ...state, customers: payload.customers };
    case CUSTOMER_LIST_FAILURE:
      return { ...state };

    case CUSTOMER_DELETE_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter((item) => item._id !== payload.id),
      };

    default:
      return { ...state };
  }
}
