import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchHolidayAsync = async (uid) => {

    try {
      const holidayRef = collection(database, "holidays");

      const q = query(
        holidayRef,
        where("createdBy", "==", String(uid))
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
        response = {
          status: 200,
          data: dataArr,
          message: t("message_holidays_get_success"),
        };
      } else {
        response = {
          status: 400,
          message: t("message_holidays_not_found"),
        };
      }

      return response;
    } catch (error) {
      let message = generateMessage(error.code);
      response = {
        status: 401,
        data: null,
        message: message || error.message,
      };
      return response;
    }

};

// export const generateHolidayNoAsync = async (uid) => {
//   const response = await generateID(uid, "Employee");
//   return response;
// };

export const createHolidayAsync = async (payload) => {
  try {
    const employRef = collection(database, "holidays");

    const q = query(
      employRef,
      where("createdBy", "==", payload?.createdBy),
      where("fy", "==", payload.fy)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      await setDoc(
        doc(database, "holidays", Number(payload._id).toString()),
        {
          ...payload,
          createdAt: new Date(),
        }
      );
      response = {
        status: 200,
        message: t("message_holiday_new_successful"),
      };
    } else {
      response = {
        status: 401,
        message: t("holiday_exists_for_fy"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateHolidayAsync = async (payload, id) => {
  console.log(payload, "payload")
  try {
    await updateDoc(doc(database, "holidays", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_holiday_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchHolidayDetailAsync = async (id) => {
  try {
    const docRef = doc(database, "holidays", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "holiday details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteHolidayAsync = async (id, recruitId) => {
  try {
    const docRef = doc(database, "holidays", String(id));
    await deleteDoc(docRef);

    await updateDoc(doc(database, "recruits", String(recruitId)), {
      isOnBoarded: false,
      lastModified: new Date(),
    });

    response = {
      status: 200,
      message: "holiday deleted succesfully...",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
