import { t } from "i18next";

export const generateMessage = (code) => {
  switch (code) {
    case "auth/user-disabled":
      return t("error_user_disabled");
    case "EMAIL_NOT_FOUND":
      return "user not found with this email";
    case "auth/user-not-found":
    case "auth/wrong-password":
      return t("error_wrong_email_or_password");
    case "auth/weak-password":
      return t("error_weak_password");
    case "auth/email-already-in-use":
      return t("error_user_already_exists");
    case "auth/invalid-email":
      return t("error_invalid_email");
    case "auth/operation-not-allowed":
      return t("not_allowed");
    // case "auth/wrong-password":
    //   return t("error_password_not_match");
    // case "auth/account-exists-with-different-credential":
    //   return "E-mail já associado a outra conta.";
    // case "auth/auth-domain-config-required":
    //   return "A configuração para autenticação não foi fornecida.";
    // case "auth/credential-already-in-use":
    //   return "Já existe uma conta para esta credencial.";
    // case "auth/operation-not-supported-in-this-environment":
    //   return "Esta operação não é suportada no ambiente que está sendo executada. Verifique se deve ser http ou https.";
    // case "auth/timeout":
    //   return "Excedido o tempo de resposta. O domínio pode não estar autorizado para realizar operações.";
    // case "auth/missing-android-pkg-name":
    //   return "Deve ser fornecido um nome de pacote para instalação do aplicativo Android.";
    // case "auth/missing-continue-uri":
    //   return "A próxima URL deve ser fornecida na solicitação.";
    // case "auth/missing-ios-bundle-id":
    //   return "Deve ser fornecido um nome de pacote para instalação do aplicativo iOS.";
    // case "auth/invalid-continue-uri":
    //   return "A próxima URL fornecida na solicitação é inválida.";
    // case "auth/unauthorized-continue-uri":
    //   return "O domínio da próxima URL não está na lista de autorizações.";
    // case "auth/invalid-dynamic-link-domain":
    //   return "O domínio de link dinâmico fornecido, não está autorizado ou configurado no projeto atual.";
    // case "auth/argument-error":
    //   return "Verifique a configuração de link para o aplicativo.";
    // case "auth/invalid-persistence-type":
    //   return "O tipo especificado para a persistência dos dados é inválido.";
    // case "auth/unsupported-persistence-type":
    //   return "O ambiente atual não suportar o tipo especificado para persistência dos dados.";
    // case "auth/invalid-credential":
    //   return "A credencial expirou ou está mal formada.";

    // case "auth/invalid-verification-code":
    //   return "O código de verificação da credencial não é válido.";
    // case "auth/invalid-verification-id":
    //   return "O ID de verificação da credencial não é válido.";
    // case "auth/custom-token-mismatch":
    //   return "O token está diferente do padrão solicitado.";
    // case "auth/invalid-custom-token":
    //   return "O token fornecido não é válido.";
    // case "auth/captcha-check-failed":
    //   return "O token de resposta do reCAPTCHA não é válido, expirou ou o domínio não é permitido.";
    // case "auth/invalid-phone-number":
    //   return "O número de telefone está em um formato inválido (padrão E.164).";
    // case "auth/missing-phone-number":
    //   return "O número de telefone é requerido.";
    // case "auth/quota-exceeded":
    //   return "A cota de SMS foi excedida.";
    // case "auth/cancelled-popup-request":
    //   return "Somente uma solicitação de janela pop-up é permitida de uma só vez.";
    // case "auth/popup-blocked":
    //   return "A janela pop-up foi bloqueado pelo navegador.";
    // case "auth/popup-closed-by-user":
    //   return "A janela pop-up foi fechada pelo usuário sem concluir o login no provedor.";
    // case "auth/unauthorized-domain":
    //   return "O domínio do aplicativo não está autorizado para realizar operações.";
    // case "auth/invalid-user-token":
    //   return "O usuário atual não foi identificado.";
    // case "auth/user-token-expired":
    //   return "O token do usuário atual expirou.";
    // case "auth/null-user":
    //   return "O usuário atual é nulo.";
    // case "auth/app-not-authorized":
    //   return "Aplicação não autorizada para autenticar com a chave informada.";
    // case "auth/invalid-api-key":
    //   return "A chave da API fornecida é inválida.";
    // case "auth/network-request-failed":
    //   return "Falha de conexão com a rede.";
    // case "auth/requires-recent-login":
    //   return "O último horário de acesso do usuário não atende ao limite de segurança.";
    // case "auth/too-many-requests":
    //   return "As solicitações foram bloqueadas devido a atividades incomuns. Tente novamente depois que algum tempo.";
    // case "auth/web-storage-unsupported":
    //   return "O navegador não suporta armazenamento ou se o usuário desativou este recurso.";
    default:
      return "";
  }
};
