import {
  RECRUIT_DELETE_SUCCESS,
  RECRUIT_DETAIL,
  RECRUIT_DETAIL_SUCCESS,
  RECRUIT_LIST,
  RECRUIT_LIST_FAILURE,
  RECRUIT_LIST_SUCCESS,
  RECRUIT_NEW,
  RECRUIT_NEW_SUCCESS,
  RECRUIT_UPDATE,
  RECRUIT_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  recruits: [],
  recruitDetail: null,
  createOrUpdated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RECRUIT_LIST:
    case RECRUIT_NEW:
    case RECRUIT_UPDATE:
    case RECRUIT_DETAIL:
      return {
        ...state,
        recruits:[],
        recruitDetail: null,
        createOrUpdated: false,
      };

    case RECRUIT_NEW_SUCCESS:
    case RECRUIT_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case RECRUIT_DETAIL_SUCCESS: {
      return { ...state, recruitDetail: payload.recruit };
    }

    case RECRUIT_LIST_SUCCESS:
      return { ...state, recruits: payload.recruits };
    case RECRUIT_LIST_FAILURE:
      return { ...state };

    case RECRUIT_DELETE_SUCCESS:
      return {
        ...state,
        recruits: state.recruits.filter((item) => item._id !== payload.id),
      };

    default:
      return { ...state };
  }
}
