import {
  GENERATE_INVOICE_ID_SUCCESS,
  INVOICE_CANCEL,
  INVOICE_CANCEL_SUCCESS,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DETAIL,
  INVOICE_DETAIL_SUCCESS,
  INVOICE_FOR_REPORT_LIST,
  INVOICE_FOR_REPORT_LIST_SUCCESS,
  INVOICE_LIST,
  INVOICE_LIST_FAILURE,
  INVOICE_LIST_SUCCESS,
  INVOICE_NEW,
  INVOICE_NEW_SUCCESS,
  INVOICE_PAYMENT_UPDATE,
  INVOICE_PAYMENT_UPDATE_SUCCESS,
  INVOICE_RESTORE,
  INVOICE_RESTORE_SUCCESS,
  INVOICE_UPDATE,
  INVOICE_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  invoices: [],
  invoicesForReport: [],
  invoiceDetail: null,
  createOrUpdated: false,
  paymentUpdated: false,
  isCancelled: false,
  invoiceNo: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INVOICE_LIST:
    case INVOICE_FOR_REPORT_LIST:
    case INVOICE_NEW:
    case INVOICE_UPDATE:
    case INVOICE_DETAIL:
    case INVOICE_PAYMENT_UPDATE:
    case INVOICE_CANCEL:
      return {
        ...state,
        invoicesForReport:[],
        createOrUpdated: false,
        paymentUpdated: false,
        invoiceDetail: null,
        isCancelled: false,
        invoiceNo: "",
      };

    case INVOICE_RESTORE:
      return { ...state, isCancelled: true };

    case INVOICE_NEW_SUCCESS:
    case INVOICE_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }
    case INVOICE_PAYMENT_UPDATE_SUCCESS: {
      return { ...state, paymentUpdated: true };
    }

    case INVOICE_DETAIL_SUCCESS: {
      return { ...state, invoiceDetail: payload.invoice };
    }

    case GENERATE_INVOICE_ID_SUCCESS: {
      return { ...state, invoiceNo: payload.invoiceNo };
    }

    case INVOICE_LIST_SUCCESS:
      return { ...state, invoices: payload.invoices };
    case INVOICE_LIST_FAILURE:
      return { ...state };

    case INVOICE_FOR_REPORT_LIST_SUCCESS:
        return { ...state, invoicesForReport: payload.invoicesForReport };

    case INVOICE_CANCEL_SUCCESS:
      return { ...state, isCancelled: true };

    case INVOICE_RESTORE_SUCCESS:
      return { ...state, isCancelled: false };

    case INVOICE_DELETE_SUCCESS:
      return {
        ...state,
        invoices: state.invoices.filter((item) => item._id !== payload.id),
      };

    default:
      return { ...state };
  }
}
