import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchProjectAsync = async (uid) => {
    try {
      const projectRef = collection(database, "projects");

      const q = query(projectRef, where("createdBy", "==", String(uid)));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
        response = {
          status: 200,
          data: dataArr,
          message: t("message_projects_get_success"),
        };
      } else {
        response = {
          status: 400,
          message: t("message_projects_not_found"),
        };
      }

      return response;
    } catch (error) {
      let message = generateMessage(error.code);
      response = {
        status: 401,
        data: null,
        message: message || error.message,
      };
      return response;
    }
};

export const generateProjectNoAsync = async (uid) => {
  const response = await generateID(uid, "Project");
  return response;
};

export const createProjectAsync = async (payload) => {
  try {
    await setDoc(doc(database, "projects", Number(payload._id).toString()), {
      ...payload,
      createdAt: new Date(),
    });
    response = {
      status: 200,
      message: t("message_project_new_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateProjectAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "projects", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_project_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchProjectDetailAsync = async (id) => {
  try {
    const projectRef = collection(database, "projects");

    const docRef = doc(database, "projects", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "project details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteProjectAsync = async (id) => {
  try {
    const docRef = doc(database, "projects", String(id));
    await deleteDoc(docRef);

    response = {
      status: 200,
      message: "project deleted succesfully...",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
