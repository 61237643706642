import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  GENERATE_PROJECT_ID,
  GENERATE_PROJECT_ID_FAILURE,
  GENERATE_PROJECT_ID_SUCCESS,
  PROJECT_DELETE,
  PROJECT_DELETE_FAILURE,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DETAIL,
  PROJECT_DETAIL_FAILURE,
  PROJECT_DETAIL_SUCCESS,
  PROJECT_LIST,
  PROJECT_LIST_FAILURE,
  PROJECT_LIST_SUCCESS,
  PROJECT_NEW,
  PROJECT_NEW_FAILURE,
  PROJECT_NEW_SUCCESS,
  PROJECT_UPDATE,
  PROJECT_UPDATE_FAILURE,
  PROJECT_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import {
  createProjectAsync,
  deleteProjectAsync,
  fetchProjectAsync,
  fetchProjectDetailAsync,
  generateProjectNoAsync,
  updateProjectAsync,
} from "../../services/project.service";

export function* watchFetchProject() {
  yield takeLatest(PROJECT_LIST, fetchProject);
}

export function* watchGenerateProjectNo() {
  yield takeLatest(GENERATE_PROJECT_ID, generateProjectNo);
}

export function* watchCreateProject() {
  yield takeLatest(PROJECT_NEW, createNewProject);
}

export function* watchUpdateProject() {
  yield takeLatest(PROJECT_UPDATE, updateProject);
}

export function* watchGetDetailProject() {
  yield takeLatest(PROJECT_DETAIL, fetchProjectDetail);
}

export function* watchDeleteProject() {
  yield takeLatest(PROJECT_DELETE, deleteProject);
}

function* fetchProject({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchProjectAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: PROJECT_LIST_SUCCESS,
        payload: { projects: response.data },
      });
    } else {
      yield put({ type: PROJECT_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROJECT_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generateProjectNo({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(generateProjectNoAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: GENERATE_PROJECT_ID_SUCCESS,
        payload: { projectNo: response.data },
      });
    } else {
      yield put({ type: GENERATE_PROJECT_ID_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_PROJECT_ID_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewProject({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createProjectAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: PROJECT_NEW_SUCCESS,
      });
    } else {
      yield put({ type: PROJECT_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROJECT_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateProject({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateProjectAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: PROJECT_UPDATE_SUCCESS,
        payload: { project: response.data },
      });
    } else {
      yield put({ type: PROJECT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROJECT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchProjectDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchProjectDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: PROJECT_DETAIL_SUCCESS,
        payload: { project: response.data },
      });
    } else {
      yield put({ type: PROJECT_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROJECT_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteProject({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteProjectAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: PROJECT_DELETE_SUCCESS,
        payload: { id: id },
      });
    } else {
      yield put({ type: PROJECT_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROJECT_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchProject),
    fork(watchGenerateProjectNo),
    fork(watchCreateProject),
    fork(watchUpdateProject),
    fork(watchGetDetailProject),
    fork(watchDeleteProject),
  ]);
}
