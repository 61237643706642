import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchTicketAsync = async (uid,haveBeenAssigned) => {
    try {
      const ticketRef = collection(database, "tickets");
    
      let q = query(ticketRef);
      if(haveBeenAssigned){
        q = query(ticketRef,where("assignedTo", "==", String(uid)))
      }else{
        q = query(ticketRef,where("createdBy", "==", String(uid)))
      }

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
                
        response = {
          status: 200,
          data: dataArr,
          message: t("message_tickets_get_success"),
        };
      } else {
        response = {
          status: 400,
          message: t("message_tickets_not_found"),
        };
      }

      return response;
    } catch (error) {
      let message = generateMessage(error.code);
      response = {
        status: 401,
        data: null,
        message: message || error.message,
      };
      return response;
    }
};

export const generateTicketIDAsync = async (uid) => {
  const response = await generateID(uid, "Ticket management");
  return response;
};

export const createTicketAsync = async (payload) => {
  try {
    await setDoc(doc(database, "tickets", Number(payload._id).toString()), {
      ...payload,
      createdAt: new Date(),
    });
    response = {
      status: 200,
      message: t("message_ticket_new_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateTicketAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "tickets", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_ticket_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchTicketDetailAsync = async (id) => {
  try {
    const ticketRef = collection(database, "tickets");

    const docRef = doc(database, "tickets", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "ticket details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteTicketAsync = async (id) => {
  try {
    const docRef = doc(database, "tickets", String(id));
    await deleteDoc(docRef);

    response = {
      status: 200,
      message: "ticket deleted",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
