import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as resources from "./resources";

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { ...resources },
    fallbackLng: "en",
    debug: true,

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
