import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  ATTENDANCE_REPORT,
  ATTENDANCE_REPORT_FAILURE,
  ATTENDANCE_REPORT_SUCCESS,
  EMPLOYEE_ATTENDANCE_DELETE,
  EMPLOYEE_ATTENDANCE_DELETE_FAILURE,
  EMPLOYEE_ATTENDANCE_DELETE_SUCCESS,
  EMPLOYEE_ATTENDANCE_DETAIL,
  EMPLOYEE_ATTENDANCE_DETAIL_FAILURE,
  EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS,
  EMPLOYEE_ATTENDANCE_LIST,
  EMPLOYEE_ATTENDANCE_LIST_FAILURE,
  EMPLOYEE_ATTENDANCE_LIST_SUCCESS,
  EMPLOYEE_ATTENDANCE_NEW,
  EMPLOYEE_ATTENDANCE_NEW_FAILURE,
  EMPLOYEE_ATTENDANCE_NEW_SUCCESS,
  EMPLOYEE_ATTENDANCE_UPDATE,
  EMPLOYEE_ATTENDANCE_UPDATE_FAILURE,
  EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS,
  GENERATE_SALARY_SLIP_NO,
  GENERATE_SALARY_SLIP_NO_FAILURE,
  GENERATE_SALARY_SLIP_NO_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import {
  createEmployeeAttendanceAsync,
  deleteEmployeeAttendanceAsync,
  fetchAttendanceReportAsync,
  fetchEmployeeAttendanceAsync,
  fetchEmployeeAttendanceDetailAsync,
  generateSalarySlipNoAsync,
  updateEmployeeAttendanceAsync,
} from "../../services/employee_attendance.service";

export function* watchFetchEmployeeAttendance() {
  yield takeLatest(EMPLOYEE_ATTENDANCE_LIST, fetchEmployeeAttendance);
}

export function* watchGenerateSalarySlipNo() {
  yield takeLatest(GENERATE_SALARY_SLIP_NO, generateSalarySlipNo);
}

export function* watchFetchAttendanceReport() {
  yield takeLatest(ATTENDANCE_REPORT, fetchAttendanceReport);
}

export function* watchCreateEmployeeAttendance() {
  yield takeLatest(EMPLOYEE_ATTENDANCE_NEW, createNewEmployeeAttendance);
}

export function* watchUpdateEmployeeAttendance() {
  yield takeLatest(EMPLOYEE_ATTENDANCE_UPDATE, updateEmployeeAttendance);
}

export function* watchGetDetailEmployeeAttendance() {
  yield takeLatest(EMPLOYEE_ATTENDANCE_DETAIL, fetchEmployeeAttendanceDetail);
}

export function* watchDeleteEmployeeAttendance() {
  yield takeLatest(EMPLOYEE_ATTENDANCE_DELETE, deleteEmployeeAttendance);
}

function* fetchEmployeeAttendance({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchEmployeeAttendanceAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: EMPLOYEE_ATTENDANCE_LIST_SUCCESS,
        payload: { employeeAttendances: response.data },
      });
    } else {
      yield put({ type: EMPLOYEE_ATTENDANCE_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOYEE_ATTENDANCE_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generateSalarySlipNo({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(generateSalarySlipNoAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: GENERATE_SALARY_SLIP_NO_SUCCESS,
        payload: { salarySlipNo: response.data },
      });
    } else {
      yield put({ type: GENERATE_SALARY_SLIP_NO_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_SALARY_SLIP_NO_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchAttendanceReport({ payload }) {
  const {data } = payload;
  try {
    const response = yield call(fetchAttendanceReportAsync,data.createdBy, data.employee, data.monthAndYear);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: ATTENDANCE_REPORT_SUCCESS,
        payload: { attendanceReports: response.data },
      });
    } else {
      yield put({ type: ATTENDANCE_REPORT_FAILURE });
      // yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ATTENDANCE_REPORT_FAILURE });
    // yield put(showMessage("error", error));
  }
}

function* createNewEmployeeAttendance({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createEmployeeAttendanceAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: EMPLOYEE_ATTENDANCE_NEW_SUCCESS,
      });
    } else {
      yield put({ type: EMPLOYEE_ATTENDANCE_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOYEE_ATTENDANCE_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateEmployeeAttendance({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateEmployeeAttendanceAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS,
        payload: { employeeAttendance: response.data },
      });
    } else {
      yield put({ type: EMPLOYEE_ATTENDANCE_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOYEE_ATTENDANCE_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchEmployeeAttendanceDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchEmployeeAttendanceDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS,
        payload: { employeeAttendance: response.data },
      });
    } else {
      yield put({ type: EMPLOYEE_ATTENDANCE_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOYEE_ATTENDANCE_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteEmployeeAttendance({ payload }) {
  const { attendanceId, employeeId } = payload;
  try {
    const response = yield call(deleteEmployeeAttendanceAsync, attendanceId, employeeId);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: EMPLOYEE_ATTENDANCE_DELETE_SUCCESS,
        payload: { id: attendanceId },
      });
    } else {
      yield put({ type: EMPLOYEE_ATTENDANCE_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOYEE_ATTENDANCE_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchEmployeeAttendance),
    fork(watchGenerateSalarySlipNo),
    fork(watchFetchAttendanceReport),
    fork(watchCreateEmployeeAttendance),
    fork(watchUpdateEmployeeAttendance),
    fork(watchGetDetailEmployeeAttendance),
    fork(watchDeleteEmployeeAttendance),
  ]);
}
