import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const generateID = async (uid, moduleSlug) => {
  try {
    const quotationRef = collection(database, "codeSetups");

    const q = query(
      quotationRef,
      where("createdBy", "==", uid),
      where("moduleSlug", "==", moduleSlug)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      let obj = {};
      querySnapshot.forEach((doc) => {
        obj = { ...doc.data() };
      });

      let previewArr = obj.preview.split(`${obj.separator}`);
      let increment = obj.lastGeneratedId
        ? String(Number(obj.lastGeneratedId) + 1).padStart(obj.digitLength, "0")
        : String(
            Number(previewArr[1]) === 1 ? Number(previewArr[1]) : +1
          ).padStart(obj.digitLength, "0");

      let generatedId =
        obj.prefix +
        obj.separator +
        increment +
        `${obj.suffix ? obj.separator : ""}` +
        obj.suffix;
      let lastGeneratedId = increment;

      if (generatedId !== "") {
        try {
          await updateDoc(doc(database, "codeSetups", String(obj._id)), {
            lastGeneratedId: lastGeneratedId,
            lastModified: new Date(),
          });

          response = {
            status: 200,
            data: generatedId,
            message: `ID for ${moduleSlug} generated successfully. `,
          };
          return response;
        } catch (error) {
          let message = generateMessage(error.code);
          response = {
            status: 401,
            data: null,
            message: message || error.message,
          };
          return response;
        }
      }
    } else {
      response = {
        status: 400,
        message: `Code setup not available for ${moduleSlug} module.`,
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
