import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "../components/Loader";

const Signin = lazy(() => import("../screens/Auth/Signin"));
const ForgetPass = lazy(() => import("../screens/Auth/Forget-password"));
const Signup = lazy(() => import("../screens/Auth/Signup/Signup"));

const AuthNavigator = () => {
  return (
    <Suspense fallback={<Loader loading={true} />}>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forget-password" element={<ForgetPass />} />
        <Route path="/*" element={<Navigate to="/signin" />} />
      </Routes>
    </Suspense>
  );
};
export default AuthNavigator;
