// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    title_signin: "[ar] Sign in",
    title_signup: "[ar] Sign up",

    next: "[ar] Next",
    prev: "[ar] Previous",

    //messages
    message:"[ar] Loading...",

    //Form page title
    personal_details: "[ar] Personal Details",
    account_setup: "[ar] Account Setup",
    success: "[ar] Success",

    //Sign in page
    error_empty_email: "[ar] Please enter email",
    error_invalid_email: "[ar] Please enter valid email",
    error_empty_password: "[ar] Please enter Password",
    error_invalid_password: "[ar] Please enter valid password",
    error_empty_cpassword: "[ar] Please enter confirm Password",
    error_invalid_cpassword: "[ar] Please enter valid confirm password",
    error_cpassword_not_match:"[ar] password and confirm password must be same",

    //sign up page
    error_empty_firstname: "[ar] Please enter firstname",
    error_invalid_firstname:
      "[ar] First name should contain only letters",
    error_empty_lastname: "[ar] Please enter lastname",
    error_invalid_lastname:
      "[ar] Last name should contain only letters",
    error_empty_phone: "[ar] Please enter contact no.",
    error_invalid_phone: "[ar] Please enter valid 10 digit no.",
  },
};
