import {
  all,
  call,
  put,
  fork,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import {
  CREATE_MASTER,
  CREATE_MASTER_FAILURE,
  CREATE_MASTER_SUCCESS,
  MASTER_LIST,
  MASTER_LIST_FAILURE,
  MASTER_LIST_SUCCESS,
  UPDATE_MASTER,
  UPDATE_MASTER_FAILURE,
  UPDATE_MASTER_STATUS,
  UPDATE_MASTER_STATUS_FAILURE,
  UPDATE_MASTER_STATUS_SUCCESS,
  UPDATE_MASTER_SUCCESS,
} from "../types";

import { hideLoader, showMessage } from "../lem/action";
import {
  createMasterOptionAsync,
  fetchMasterListAsync,
  updateMasterOptionAsync,
} from "../../services/masters.service";

export function* watchFetchMasterList() {
  yield takeEvery(MASTER_LIST, fetchMasterList);
}

export function* watchCreateMasterOption() {
  yield takeLatest(CREATE_MASTER, createMasterOptions);
}

export function* watchUpdateMasterOption() {
  yield takeLatest(UPDATE_MASTER, updateMasterOptions);
}

export function* watchUpdateMasterStatus() {
  yield takeLatest(UPDATE_MASTER_STATUS, updateMasterStatus);
}

function* fetchMasterList({ payload }) {
  const { slug, createdBy } = payload;
  try {
    const response = yield call(fetchMasterListAsync, slug, createdBy);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: MASTER_LIST_SUCCESS,
        payload: { slug: slug, data: response.data },
      });
    } else {
      yield put({ type: MASTER_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createMasterOptions({ payload }) {
  const { masterId, masterName, data } = payload;
  try {
    const response = yield call(
      createMasterOptionAsync,
      masterId,
      masterName,
      data
    );
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: CREATE_MASTER_SUCCESS,
        payload: { masterId: masterId, data: response.data },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: CREATE_MASTER_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CREATE_MASTER_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateMasterOptions({ payload }) {
  const { slug, data, id } = payload;
  try {
    const response = yield call(updateMasterOptionAsync, slug, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: UPDATE_MASTER_SUCCESS,
        payload: { data: response.data, id: id },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: UPDATE_MASTER_FAILURE });
      yield put(showMessage("error", response.data.message));
    }
  } catch (error) {
    yield put({ type: UPDATE_MASTER_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateMasterStatus({ payload }) {
  const { slug, data, id } = payload;
  try {
    const response = yield call(updateMasterOptionAsync,slug, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: UPDATE_MASTER_STATUS_SUCCESS,
        payload: { slug: slug, data: response.data, id: id },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: UPDATE_MASTER_STATUS_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: UPDATE_MASTER_STATUS_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchMasterList),
    fork(watchCreateMasterOption),
    fork(watchUpdateMasterOption),
    fork(watchUpdateMasterStatus),
  ]);
}
