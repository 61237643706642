import TokenService from "./token.service";
import { database, authentication } from "../config/firebase-config";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchUserManagementAsync = async (uid) => {
  try {
    const userManagementRef = collection(database, "users");

    const q = query(userManagementRef, where("createdBy", "==", String(uid)));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
      });
      response = {
        status: 200,
        data: dataArr,
        message: t("message_user_managements_get_success"),
      };
    } else {
      response = {
        status: 400,
        message: t("message_users_not_found"),
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const createNewUserAsync = async (request) => {
  // const userObj = { ...request };
  const { email } = request;
  try {
    const createUserResponse = await createUserWithEmailAndPassword(
      authentication,
      email,
      String(Math.random())
    );
    let userId = createUserResponse.user.uid;
    await sendPasswordResetEmail(authentication, email);
    await setDoc(doc(database, "users", createUserResponse.user.uid), {
      ...request,
      userId: userId,
      createdAt: new Date(),
    });

    const employOnboardingRef = collection(database, "employOnboardings");

    const q = query(
      employOnboardingRef,
      where("officialEmailId", "==", String(email))
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const dataArr = [];
      querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
      });
      let empObj = dataArr.find(item=>item.officialEmailId == email)
      await updateDoc(doc(database, "employOnboardings", String(empObj._id)), {
        isUserCreated:true,
        lastModified: new Date(),
      });      
    }

    response = {
      status: 200,
      message: t("message_user_new_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateUserManagementAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "users", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_user_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchUserManagementDetailAsync = async (id) => {
  try {
    const docRef = doc(database, "users", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "user details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const deleteUserManagementAsync = async (id) => {
  try {
    const docRef = doc(database, "users", String(id));
    await deleteDoc(docRef);

    response = {
      status: 200,
      message: "user deleted succesfully...",
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const logout = () => TokenService.removeUser();
