import { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { verifyPermission } from "../App";

import Loader from "../components/Loader";

const ProfileForm = lazy(() => import("../screens/Profile/ProfileForm"));
const Dashboard = lazy(() => import("../screens/Home/Dashboard"));

const CustomerList = lazy(() => import("../screens/Customer/customerList"));
const CustomerForm = lazy(() => import("../screens/Customer/customerForm"));

const QuotationList = lazy(() => import("../screens/Quotation/quotationList"));
const QuotationForm = lazy(() => import("../screens/Quotation/quotationForm"));
const PDF = lazy(() => import("../screens/Quotation/PDF"));

const InvoiceList = lazy(() => import("../screens/Invoice/invoiceList"));
const InvoiceForm = lazy(() => import("../screens/Invoice/invoiceForm"));
const InvoicePDF = lazy(() => import("../screens/Invoice/PDF"));

const EmployeeList = lazy(() =>
  import("../screens/Employ_onboarding/employOnboardingList")
);
const EmployeeForm = lazy(() =>
  import("../screens/Employ_onboarding/employOnboardingForm")
);

const HolidaysList = lazy(() =>
  import("../screens/HolidaySetup/HolidaysList")
);
const HolidaySetupForm = lazy(() =>
  import("../screens/HolidaySetup/HolidaySetupForm")
);

const EmployeeAttendance = lazy(()=> import("../screens/EmployeeAtendance/employeeAtendence"))
const AttendanceReport = lazy(()=> import("../screens/EmployeeAtendance/attendanceReport"))
const AttendanceReportPDF = lazy(()=> import("../screens/EmployeeAtendance/PDF"))

const ProjectList = lazy(() => import("../screens/Project/projectList"));
const ProjectForm = lazy(() => import("../screens/Project/projectForm"));

const TicketsList = lazy(() => import("../screens/Ticket/ticketsList"));
const TicketsForm = lazy(() => import("../screens/Ticket/ticketsForm"));

const RecruitList = lazy(() => import("../screens/Recruit/recruitList"));
const RecruitForm = lazy(() => import("../screens/Recruit/recruitForm"));

const UserList = lazy(() => import("../screens/user/userList"));
const UserForm = lazy(() => import("../screens/user/userForm"));

const RolesAndRightsList = lazy(() =>
  import("../screens/roles_and_rights/rolesAndRightsList")
);
const RolesAndRightsForm = lazy(() =>
  import("../screens/roles_and_rights/rolesAndRightsForm")
);

const JobTypeList = lazy(() =>
  import("../screens/Masters/job_type/jobTypeList")
);
const RecruitmentStatusList = lazy(() =>
  import("../screens/Masters/recruit_status/recruitmentStatusList")
);
const EmploymentStatusList = lazy(() =>
  import("../screens/Masters/employment_status/employmentStatusList")
);
const DesignationList = lazy(() =>
  import("../screens/Masters/designation/designationList")
);
const CurrencyList = lazy(() =>
  import("../screens/Masters/currency/currencyList")
);

const Reports = lazy(() => import("../screens/Reports/reportsForm"));

//GST master master page
const GSTMasterList = lazy(() =>
  import("../screens/Masters/gst_master/GSTMasterList.js")
);
const GSTMasterForm = lazy(() =>
  import("../screens/Masters/gst_master/GSTMasterForm")
);

const CodeSetupList = lazy(() => import("../screens/Codesetup/codeSetupList"));
const CodeSetupForm = lazy(() => import("../screens/Codesetup/codeSetupForm"));

const AppNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.pathname.startsWith("/customer"))
  if (
    (location.pathname === "/company-profile" &&
      !verifyPermission("UPDATE_COMPANY_PROFILE")) ||
    (location.pathname === "/customer" && !verifyPermission("READ_CUSTOMER")) ||
    (location.pathname.startsWith("/customer-form") &&
      (!verifyPermission("CREATE_CUSTOMER") ||
        !verifyPermission("UPDATE_CUSTOMER"))) ||
    (location.pathname === "/quotation" &&
      !verifyPermission("READ_QUOTATION")) ||
    (location.pathname.startsWith("/quotation-form") &&
      (!verifyPermission("CREATE_QUOTATION") ||
        !verifyPermission("UPDATE_QUOTATION"))) ||
    (location.pathname === "/invoice" && !verifyPermission("READ_INVOICE")) ||
    (location.pathname.startsWith("/invoice-form") &&
      (!verifyPermission("CREATE_INVOICE") ||
        !verifyPermission("UPDATE_INVOICE"))) ||
    (location.pathname === "/roles_and_rights" &&
      !verifyPermission("READ_ROLE")) ||
    (location.pathname.startsWith("/roles_and_rights-form") &&
      (!verifyPermission("CREATE_ROLE") || !verifyPermission("UPDATE_ROLE"))) ||
    (location.pathname === "/user" &&
      !verifyPermission("READ_USER_MANAGEMENT")) ||
    (location.pathname.startsWith("/user-form") &&
      (!verifyPermission("CREATE_USER_MANAGEMENT") ||
        !verifyPermission("UPDATE_USER_MANAGEMENT"))) ||
    (location.pathname === "/code-setup" &&
      !verifyPermission("READ_CODE_SETUP")) ||
    (location.pathname.startsWith("/code-setup-form") &&
      (!verifyPermission("CREATE_CODE_SETUP") ||
        !verifyPermission("UPDATE_CODE_SETUP"))) ||
    (location.pathname.startsWith("/reports") &&
      !verifyPermission("READ_REPORT")) ||
    (location.pathname === "/recruit" && !verifyPermission("READ_RECRUIT")) ||
    (location.pathname.startsWith("/recruit-form") &&
      (!verifyPermission("CREATE_RECRUIT") ||
        !verifyPermission("UPDATE_RECRUIT"))) ||
    (location.pathname === "/employ_onboarding" &&
      !verifyPermission("READ_EMPLOYEE")) ||
    (location.pathname.startsWith("/employ_onboarding-form") &&
      (!verifyPermission("CREATE_EMPLOYEE") ||
        !verifyPermission("UPDATE_EMPLOYEE"))) ||
    (location.pathname === "/project" && !verifyPermission("READ_PROJECT")) ||
    (location.pathname.startsWith("/project-form") &&
      (!verifyPermission("CREATE_PROJECT") ||
        !verifyPermission("UPDATE_PROJECT"))) ||
    (location.pathname === "/tickets" && !verifyPermission("READ_TICKET")) ||
    (location.pathname.startsWith("/tickets-form") &&
      (!verifyPermission("CREATE_TICKET") ||
        !verifyPermission("UPDATE_TICKET")))
  ) {
    navigate("/");
  }
  return (
    <Suspense fallback={<Loader loading={true} />}>
      <Routes>
        <Route path="/company-profile" element={<ProfileForm />} />

        <Route path="/customer" element={<CustomerList />} />
        <Route path="/customer-form" element={<CustomerForm />} />
        <Route path="/customer-form/:id" element={<CustomerForm />} />

        <Route path="/quotation" element={<QuotationList />} />
        <Route path="/quotation-form" element={<QuotationForm />} />
        <Route path="/quotation-form/:id" element={<QuotationForm />} />
        <Route path="/quotation/PDF/:id" element={<PDF />} />

        <Route path="/invoice" element={<InvoiceList />} />
        <Route path="/invoice-form" element={<InvoiceForm />} />
        <Route path="/invoice-form/:id" element={<InvoiceForm />} />
        <Route path="/invoice/PDF/:id" element={<InvoicePDF />} />

        <Route path="/recruit" element={<RecruitList />} />
        <Route path="/recruit-form" element={<RecruitForm />} />
        <Route path="/recruit-form/:id" element={<RecruitForm />} />

        <Route path="/employ_onboarding" element={<EmployeeList />} />
        <Route path="/employ_onboarding-form" element={<EmployeeForm />} />
        <Route path="/employ_onboarding-form/:id" element={<EmployeeForm />} />

        <Route path="/holiday-setup" element={<HolidaysList />} />
        <Route path="/holiday-setup-form" element={<HolidaySetupForm />} />
        <Route path="/holiday-setup-form/:id" element={<HolidaySetupForm />} />

        <Route path="/employee_attendance" element={<EmployeeAttendance />} />
        <Route path="/attendance_report" element={<AttendanceReport />} />
        <Route path="/attendance_report/PDF/:id" element={<AttendanceReportPDF />} />

        <Route path="/project" element={<ProjectList />} />
        <Route path="/project-form" element={<ProjectForm />} />
        <Route path="/project-form/:id" element={<ProjectForm />} />

        <Route path="/tickets" element={<TicketsList />} />
        <Route path="/tickets-form" element={<TicketsForm />} />
        <Route path="/tickets-form/:id" element={<TicketsForm />} />

        <Route path="/user" element={<UserList />} />
        <Route path="/user-form" element={<UserForm />} />
        <Route path="/user-form/:id" element={<UserForm />} />

        <Route path="/roles_and_rights" element={<RolesAndRightsList />} />
        <Route path="/roles_and_rights-form" element={<RolesAndRightsForm />} />
        <Route
          path="/roles_and_rights-form/:id"
          element={<RolesAndRightsForm />}
        />

        <Route exact path={"/masters/job_type"} element={<JobTypeList />} />
        <Route
          exact
          path={"/masters/recruitment_status"}
          element={<RecruitmentStatusList />}
        />
        <Route
          exact
          path={"/masters/employment_status"}
          element={<EmploymentStatusList />}
        />
        <Route exact path={"/masters/gst_master"} element={<GSTMasterList />} />
        <Route
          exact
          path={"/masters/designation"}
          element={<DesignationList />}
        />
        <Route exact path={"/masters/currency"} element={<CurrencyList />} />
        <Route
          exact
          path={"/masters/gst_master-form"}
          element={<GSTMasterForm />}
        />
        <Route
          exact
          path={"/masters/gst_master-form/:id"}
          element={<GSTMasterForm />}
        />

        <Route
          exact
          path={"/reports" || "reports-form"}
          element={<Reports />}
        />

        <Route path="/code-setup" element={<CodeSetupList />} />
        <Route path="/code-setup-form" element={<CodeSetupForm />} />
        <Route path="/code-setup-form/:id" element={<CodeSetupForm />} />

        <Route path="/*" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Suspense>
  );
};
export default AppNavigator;
