import { SET_LOADER, SET_MESSAGE } from "../types";

export const showLoader = (message) => ({
  type: SET_LOADER,
  payload: { loading: true, message: message },
});

export const hideLoader = () => ({
  type: SET_LOADER,
  payload: { loading: false, message: undefined },
});

export const showMessage = (
  messageType = "info",
  text = "",
  duration = 2000,
  position = "top-center"
) => {
  return {
    type: SET_MESSAGE,
    payload: {
      message: {
        type: messageType,
        text: text,
        duration: messageType === "error" ? 3000 : duration,
        position: position,
      },
    },
  };
};

export const hideMessage = () => ({
  type: SET_MESSAGE,
  payload: { message: undefined },
});
