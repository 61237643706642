import {
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchProfilesAsync = async (uid) => {
  try {
    const docRef = doc(database, "companyProfile", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
    const bankDetailRef = doc(database, "companyBankDetails", uid);
    const bankDetailsSnap = await getDoc(bankDetailRef);
      if(bankDetailsSnap.exists()){
        response = {
          status: 200,
          data: { profile: { ...docSnap.data() },bankDetails:{...bankDetailsSnap.data()} },
        };
      }else{      
      response = {
        status: 200,
        data: { profile: { ...docSnap.data() } },
      };
    }
    } else {
      response = {
        status: 400,
        data: null,
        message: t("message_error_fetching_profile"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error,
    };
    return response;
  }
};

export const addProfileDetailsAsync = async (payload) => {
  try {
    await setDoc(doc(database, "companyProfile", payload.createdBy), {
      ...payload,
      updatedAt: new Date(),
    });
    response = {
      status: 200,
      message: t("message_profile_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
export const addProfileBankDetailsAsync = async (payload) => {
  try {
    await setDoc(doc(database, "companyBankDetails", payload.createdBy), {
      ...payload,
      updatedAt: new Date(),
    });
    response = {
      status: 200,
      message: t("message_profile_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
