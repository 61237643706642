import { async } from "@firebase/util";
import { collection, getDocs, query } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { t } from "i18next";
import { database, storage } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const getAllMasters = async () => {
  const master = collection(database, "master");

  const q = query(master);
  await getDocs(q).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
        response = {
          status: 200,
          data: dataArr,
          message: t("message_quotations_get_success"),
        };
        return response;
      } else {
        response = {
          status: 400,
          message: t("message_quotations_not_found"),
        };
      }
    })
    .catch((error) => {
      let message = generateMessage(error.code);
      response = {
        status: 400,
        data: null,
        message: message || error.message,
      };
      return response;
    });

  return response;
};

export const uploadFilesAsync = async(companyName, documentType, isPublic, file) => { 
 try {
  const storageRef = ref(storage, `/${companyName}/${documentType}/${file.name}`);
  let uploadResponse = await uploadBytes(storageRef, file)
  if(uploadResponse){
    let responseUrl = await getDownloadURL(storageRef)
    response = {
      status:200,
      data : responseUrl,
      message:"File uploaded successfully"
      }  
    }
  return response;
} catch (error) {
  let message = generateMessage(error.code);
  response = {
    status: 401,
    data: null,
    message: message || error.message,
  };
  return response;
}
};