import { MASTER_GET_ALL_SUCCESS } from "../types";

const initialState = {
  allMasters: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MASTER_GET_ALL_SUCCESS:
      return { ...state, allMasters: payload.masters };
    default:
      return { ...state };
  }
}
