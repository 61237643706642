import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  GENERATE_INVOICE_ID,
  GENERATE_INVOICE_ID_FAILURE,
  GENERATE_INVOICE_ID_SUCCESS,
  INVOICE_CANCEL,
  INVOICE_CANCEL_FAILURE,
  INVOICE_CANCEL_SUCCESS,
  INVOICE_DELETE,
  INVOICE_DELETE_FAILURE,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DETAIL,
  INVOICE_DETAIL_FAILURE,
  INVOICE_DETAIL_SUCCESS,
  INVOICE_FOR_REPORT_LIST,
  INVOICE_FOR_REPORT_LIST_FAILURE,
  INVOICE_FOR_REPORT_LIST_SUCCESS,
  INVOICE_LIST,
  INVOICE_LIST_FAILURE,
  INVOICE_LIST_SUCCESS,
  INVOICE_NEW,
  INVOICE_NEW_FAILURE,
  INVOICE_NEW_SUCCESS,
  INVOICE_PAYMENT_UPDATE,
  INVOICE_PAYMENT_UPDATE_FAILURE,
  INVOICE_PAYMENT_UPDATE_SUCCESS,
  INVOICE_RESTORE,
  INVOICE_RESTORE_FAILURE,
  INVOICE_RESTORE_SUCCESS,
  INVOICE_UPDATE,
  INVOICE_UPDATE_FAILURE,
  INVOICE_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import {
  cancelInvoiceAsync,
  createInvoiceAsync,
  deleteInvoiceAsync,
  fetchInvoiceAsync,
  fetchInvoiceDetailAsync,
  fetchInvoicesForReportAsync,
  generateInvoiceNoAsync,
  restoreInvoiceAsync,
  updateInvoiceAsync,
  updatePaymentInfoAsync,
} from "../../services/invoice.services.js";

export function* watchFetchInvoice() {
  yield takeLatest(INVOICE_LIST, fetchInvoice);
}

export function* watchFetchInvoicesForReport() {
  yield takeLatest(INVOICE_FOR_REPORT_LIST, fetchInvoicesForReport);
}

export function* watchGenerateInvoiceNo() {
  yield takeLatest(GENERATE_INVOICE_ID, generateInvoiceNo);
}

export function* watchCreateInvoice() {
  yield takeLatest(INVOICE_NEW, createNewInvoice);
}

export function* watchUpdateInvoice() {
  yield takeLatest(INVOICE_UPDATE, updateInvoice);
}

export function* watchUpdatePaymentInfo() {
  yield takeLatest(INVOICE_PAYMENT_UPDATE, updatePaymentInfo);
}
export function* watchCancelInvoice() {
  yield takeLatest(INVOICE_CANCEL, cancelInvoice);
}
export function* watchRestoreInvoice() {
  yield takeLatest(INVOICE_RESTORE, restoreInvoice);
}

export function* watchGetInvoiceDetail() {
  yield takeLatest(INVOICE_DETAIL, fetchInvoiceDetail);
}

export function* watchDeleteInvoice() {
  yield takeLatest(INVOICE_DELETE, deleteInvoice);
}

function* fetchInvoice({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchInvoiceAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: INVOICE_LIST_SUCCESS,
        payload: { invoices: response.data },
      });
    } else {
      yield put({ type: INVOICE_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchInvoicesForReport({ payload }) {
  const { uid,startDate,endDate } = payload;
  try {
    const response = yield call(fetchInvoicesForReportAsync, uid, startDate, endDate);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: INVOICE_FOR_REPORT_LIST_SUCCESS,
        payload: { invoicesForReport: response.data },
      });
    } else {
      yield put({ type: INVOICE_FOR_REPORT_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_FOR_REPORT_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generateInvoiceNo({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(generateInvoiceNoAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: GENERATE_INVOICE_ID_SUCCESS,
        payload: { invoiceNo: response.data },
      });
    } else {
      yield put({ type: GENERATE_INVOICE_ID_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_INVOICE_ID_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewInvoice({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createInvoiceAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: INVOICE_NEW_SUCCESS,
      });
    } else {
      yield put({ type: INVOICE_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateInvoice({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateInvoiceAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: INVOICE_UPDATE_SUCCESS,
        payload: { invoice: response.data },
      });
    } else {
      yield put({ type: INVOICE_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}
function* updatePaymentInfo({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updatePaymentInfoAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: INVOICE_PAYMENT_UPDATE_SUCCESS,
        payload: { invoice: response.data },
      });
    } else {
      yield put({ type: INVOICE_PAYMENT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_PAYMENT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}
function* cancelInvoice({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(cancelInvoiceAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: INVOICE_CANCEL_SUCCESS,
      });
    } else {
      yield put({ type: INVOICE_CANCEL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_CANCEL_FAILURE });
    yield put(showMessage("error", error));
  }
}
function* restoreInvoice({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(restoreInvoiceAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: INVOICE_RESTORE_SUCCESS,
      });
    } else {
      yield put({ type: INVOICE_RESTORE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_RESTORE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchInvoiceDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchInvoiceDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: INVOICE_DETAIL_SUCCESS,
        payload: { invoice: response.data },
      });
    } else {
      yield put({ type: INVOICE_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteInvoice({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteInvoiceAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: INVOICE_DELETE_SUCCESS,
        payload: { id: id },
      });
    } else {
      yield put({ type: INVOICE_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: INVOICE_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchInvoice),
    fork(watchFetchInvoicesForReport),
    fork(watchGenerateInvoiceNo),
    fork(watchCreateInvoice),
    fork(watchUpdateInvoice),
    fork(watchUpdatePaymentInfo),
    fork(watchCancelInvoice),
    fork(watchRestoreInvoice),
    fork(watchGetInvoiceDetail),
    fork(watchDeleteInvoice),
  ]);
}
