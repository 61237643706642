import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  GENERATE_EMPLOY_ONBOARDING_ID,
  GENERATE_EMPLOY_ONBOARDING_ID_FAILURE,
  GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS,
  EMPLOY_ONBOARDING_DELETE,
  EMPLOY_ONBOARDING_DELETE_FAILURE,
  EMPLOY_ONBOARDING_DELETE_SUCCESS,
  EMPLOY_ONBOARDING_DETAIL,
  EMPLOY_ONBOARDING_DETAIL_FAILURE,
  EMPLOY_ONBOARDING_DETAIL_SUCCESS,
  EMPLOY_ONBOARDING_LIST,
  EMPLOY_ONBOARDING_LIST_FAILURE,
  EMPLOY_ONBOARDING_LIST_SUCCESS,
  EMPLOY_ONBOARDING_NEW,
  EMPLOY_ONBOARDING_NEW_FAILURE,
  EMPLOY_ONBOARDING_NEW_SUCCESS,
  EMPLOY_ONBOARDING_UPDATE,
  EMPLOY_ONBOARDING_UPDATE_FAILURE,
  EMPLOY_ONBOARDING_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import { createEmployOnboardingAsync, deleteEmployOnboardingAsync, fetchEmployOnboardingAsync, fetchEmployOnboardingDetailAsync, generateEmployOnboardingNoAsync, updateEmployOnboardingAsync } from "../../services/employ_onboarding.service.js";


export function* watchFetchEmployOnboarding() {
  yield takeLatest(EMPLOY_ONBOARDING_LIST, fetchEmployOnboarding);
}

export function* watchGenerateEmployOnboardingNo() {
  yield takeLatest(GENERATE_EMPLOY_ONBOARDING_ID, generateEmployOnboardingNo);
}

export function* watchCreateEmployOnboarding() {
  yield takeLatest(EMPLOY_ONBOARDING_NEW, createNewEmployOnboarding);
}

export function* watchUpdateEmployOnboarding() {
  yield takeLatest(EMPLOY_ONBOARDING_UPDATE, updateEmployOnboarding);
}

export function* watchGetDetailEmployOnboarding() {
  yield takeLatest(EMPLOY_ONBOARDING_DETAIL, fetchEmployOnboardingDetail);
}

export function* watchDeleteEmployOnboarding() {
  yield takeLatest(EMPLOY_ONBOARDING_DELETE, deleteEmployOnboarding);
}

function* fetchEmployOnboarding({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchEmployOnboardingAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: EMPLOY_ONBOARDING_LIST_SUCCESS,
        payload: { employOnboardings: response.data },
      });
    } else {
      yield put({ type: EMPLOY_ONBOARDING_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOY_ONBOARDING_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generateEmployOnboardingNo({ payload }) {
  const { uid } = payload;
  console.log(uid)
  try {
    const response = yield call(generateEmployOnboardingNoAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS,
        payload: { employeeId: response.data },
      });
    } else {
      yield put({ type: GENERATE_EMPLOY_ONBOARDING_ID_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_EMPLOY_ONBOARDING_ID_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewEmployOnboarding({ payload }) {
  console.log(payload)
  const { data } = payload;
  try {
    const response = yield call(createEmployOnboardingAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: EMPLOY_ONBOARDING_NEW_SUCCESS,
      });
    } else {
      yield put({ type: EMPLOY_ONBOARDING_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOY_ONBOARDING_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateEmployOnboarding({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateEmployOnboardingAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: EMPLOY_ONBOARDING_UPDATE_SUCCESS,
        payload: { employOnboarding: response.data },
      });
    } else {
      yield put({ type: EMPLOY_ONBOARDING_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOY_ONBOARDING_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchEmployOnboardingDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchEmployOnboardingDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: EMPLOY_ONBOARDING_DETAIL_SUCCESS,
        payload: { employOnboarding: response.data },
      });
    } else {
      yield put({ type: EMPLOY_ONBOARDING_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOY_ONBOARDING_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteEmployOnboarding({ payload }) {
  const { id,recruitId } = payload;
  try {
    const response = yield call(deleteEmployOnboardingAsync, id,recruitId);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: EMPLOY_ONBOARDING_DELETE_SUCCESS,
        payload: { id: id },
      });
    } else {
      yield put({ type: EMPLOY_ONBOARDING_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: EMPLOY_ONBOARDING_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchEmployOnboarding),
    fork(watchGenerateEmployOnboardingNo),
    fork(watchCreateEmployOnboarding),
    fork(watchUpdateEmployOnboarding),
    fork(watchGetDetailEmployOnboarding),
    fork(watchDeleteEmployOnboarding),
  ]);
}
