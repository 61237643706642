import {
  GENERATE_QUOTATION_ID_SUCCESS,
  QUOTATION_DELETE_SUCCESS,
  QUOTATION_DETAIL,
  QUOTATION_DETAIL_SUCCESS,
  QUOTATION_LIST,
  QUOTATION_LIST_FAILURE,
  QUOTATION_LIST_SUCCESS,
  QUOTATION_NEW,
  QUOTATION_NEW_SUCCESS,
  QUOTATION_UPDATE,
  QUOTATION_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  quotations: [],
  quotationNo: "",
  quotationDetail: null,
  createOrUpdated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case QUOTATION_LIST:
    case QUOTATION_NEW:
    case QUOTATION_UPDATE:
    case QUOTATION_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        quotationDetail: null,
        quotationNo: "",
      };

    case QUOTATION_NEW_SUCCESS:
    case QUOTATION_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case QUOTATION_DETAIL_SUCCESS: {
      return { ...state, quotationDetail: payload.quotation };
    }
    case GENERATE_QUOTATION_ID_SUCCESS: {
      return { ...state, quotationNo: payload.quotationNo };
    }

    case QUOTATION_LIST_SUCCESS:
      return { ...state, quotations: payload.quotations };
    case QUOTATION_LIST_FAILURE:
      return { ...state };

    case QUOTATION_DELETE_SUCCESS:
      return {
        ...state,
        quotations: state.quotations.filter((item) => item._id !== payload.id),
      };

    default:
      return { ...state };
  }
}
