import { PROFILE_GET, PROFILE_DETAIL, PROFILE_BANK_DETAIL } from "../types";

export const fetchProfileInfo = (uid) => ({
  type: PROFILE_GET,
  payload: { uid: uid },
});

export const addOrUpdateBankDetail = (data) => ({
  type: PROFILE_BANK_DETAIL,
  payload: { data: data },
});

// export const addOrUpdateGeneralDetail = (data, signatureFile) => ({
//   type: PROFILE_GENERAL_DETAIL,
//   payload: { data: data, signatureFile: signatureFile },
// });

export const addOrUpdateProfileDetail = (data, logoFile, signatureFile) => ({
  type: PROFILE_DETAIL,
  payload: { data: data, logoFile, signatureFile },
});

// export const changePasswordAction = (values) => ({
//   type: CHANGE_PASSWORD,
//   payload: { data: values },
// });

// export const resetChangePasswordFlag = () => ({
//   type: RESET_CHANGE_PASSWORD_FLAG,
//   payload: {},
// });

// export const updateUserProfile = (values) => ({
//   type: UPDATE_USER_PROFILE,
//   payload: { data: values },
// });
