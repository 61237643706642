import { SET_LOADER, SET_MESSAGE } from "../types";

const initialState = {
  loading: false,
  message: undefined,
};

/**
 * Message object can be {type : 'success/info/warning/error', message: 'test', duration: 2000}
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADER: {
      return { ...state, loading: payload.loading, message: payload.message };
    }
    case SET_MESSAGE: {
      return { ...state, message: payload.message, loading: false };
    }
    default:
      return { ...state };
  }
}
