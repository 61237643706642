import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import lemReducer from "./lem/reducer";
import commonReducer from "./common/reducer";
import masterReducer from "./masters/reducer";
import profileReducer from "./profile/reducer";
import customerReducer from "./customer/reducer";
import quotationReducer from "./quotation/reducer";
import invoiceReducer from "./invoice/reducer";
import employOnboardingReducer from "./employ_onboarding/reducer";
import employAttendanceReducer from "./employeeAttendance/reducer";
import projectReducer from "./project/reducer";
import ticketReducer from "./ticket/reducer";
import recruitReducer from "./recruit/reducer";
import userManagementReducer from "./user/reducer";
import rolesReducer from "./roles_and_rights/reducer";
import gstMasterReducer from "./gst_master/reducer";
import codeSetupReducer from "./codesetup/reducer";
import holidaysReducer from "./holidays/reducer";

const reducers = combineReducers({
  Auth: authReducer,
  Lem: lemReducer,
  Common: commonReducer,
  Masters: masterReducer,
  Profile: profileReducer,
  Customer: customerReducer,
  Quotation: quotationReducer,
  Invoice: invoiceReducer,
  EmployOnboarding:employOnboardingReducer,
  EmployAttendance:employAttendanceReducer,
  Project:projectReducer,
  Ticket:ticketReducer,
  Recruit: recruitReducer,
  User: userManagementReducer,
  Roles: rolesReducer,
  GSTMaster: gstMasterReducer,
  CodeSetup: codeSetupReducer,
  Holidays: holidaysReducer,
});

export default reducers;
