import {
  all,
  call,
  put,
  fork,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import { hideLoader, showMessage } from "../../redux/lem/action";
import {
  GST_MASTER_DELETE,
  GST_MASTER_DELETE_FAILURE,
  GST_MASTER_DELETE_SUCCESS,
  GST_MASTER_DETAIL,
  GST_MASTER_DETAIL_FAILURE,
  GST_MASTER_DETAIL_SUCCESS,
  GST_MASTER_LIST,
  GST_MASTER_LIST_FAILURE,
  GST_MASTER_LIST_SUCCESS,
  GST_MASTER_NEW,
  GST_MASTER_NEW_FAILURE,
  GST_MASTER_NEW_SUCCESS,
  GST_MASTER_UPDATE,
  GST_MASTER_UPDATE_FAILURE,
  GST_MASTER_UPDATE_SUCCESS,
} from "../types";

import {
  createGSTMasterAsync,
  deleteGSTMasterAsync,
  fetchGSTMasterDetailAsync,
  fetchGSTMasterListAsync,
  updateGSTMasterAsync,
} from "../../services/gst_master.service";

export function* watchFetchGSTMasterList() {
  yield takeLatest(GST_MASTER_LIST, fetchGSTMasterList);
}

export function* watchCreateGSTMaster() {
  yield takeLatest(GST_MASTER_NEW, createNewGSTMaster);
}

export function* watchUpdateGSTMaster() {
  yield takeLatest(GST_MASTER_UPDATE, updateGSTMaster);
}

export function* watchGetDetailGSTMaster() {
  yield takeLatest(GST_MASTER_DETAIL, fetchGSTMasterDetail);
}

export function* watchDeleteGSTMaster() {
  yield takeLatest(GST_MASTER_DELETE, deleteGSTMaster);
}

function* fetchGSTMasterList({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchGSTMasterListAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: GST_MASTER_LIST_SUCCESS,
        payload: { GSTMasters: response.data },
      });
    } else {
      yield put({ type: GST_MASTER_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GST_MASTER_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewGSTMaster({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createGSTMasterAsync, data);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: GST_MASTER_NEW_SUCCESS,
        payload: { GSTMaster: response.data },
      });
    } else {
      yield put({ type: GST_MASTER_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GST_MASTER_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateGSTMaster({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateGSTMasterAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put(showMessage("success", response.message));
      yield put({
        type: GST_MASTER_UPDATE_SUCCESS,
        payload: { GSTMaster: response.data },
      });
    } else {
      yield put({ type: GST_MASTER_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GST_MASTER_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchGSTMasterDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchGSTMasterDetailAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: GST_MASTER_DETAIL_SUCCESS,
        payload: { GSTMaster: response.data },
      });
    } else {
      yield put({ type: GST_MASTER_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GST_MASTER_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteGSTMaster({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteGSTMasterAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: GST_MASTER_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: GST_MASTER_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GST_MASTER_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchGSTMasterList),
    fork(watchCreateGSTMaster),
    fork(watchUpdateGSTMaster),
    fork(watchGetDetailGSTMaster),
    fork(watchDeleteGSTMaster),
  ]);
}
