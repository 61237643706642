import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import AppNavigator from "./views/AppNavigator";
import AuthNavigator from "./views/AuthNavigator";
import "./App.scss";
import cogoToast from "cogo-toast";
import { logoutUser } from "./redux/auth/actions";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { fetchProfileInfo } from "./redux/profile/actions";
import { store } from "./redux/index";

export const verifyPermission = (key) => {
  const permissions = store.getState().Auth.permissions;
  if (permissions && permissions.length !== 0) {
    // console.log("PERMISSION", permissions);
    return permissions.includes(key);
  }
  return true;
};

function App() {
  const { t } = useTranslation();
  const authState = useSelector((state) => state.Auth);
  const profileState = useSelector((state) => state.Profile);
  const dispatch = useDispatch();

  const closeOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.remove("active");
  };

  const handleOnIdle = (event) => {
    // console.log("user is idle", event);
    // console.log("last active", getLastActiveTime());
    if (getRemainingTime() === 0 && authState.isLoggedIn) {
      cogoToast.info(t("your_session_has_been_expired"));
      dispatch(logoutUser());
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 250,
  });

  useEffect(() => {
    if (authState.isLoggedIn) {
      dispatch(
        fetchProfileInfo(
          authState.profile?.createdBy
            ? authState.profile?.createdBy
            : authState.profile?.userId
        )
      );
    }
  }, [profileState.createOrUpdate, authState.isLoggedIn, authState.profile]);

  if (authState.isLoggedIn) {
    return (
      <div className="container-scroller">
        <Sidebar />
        <div className="container-fluid page-body-wrapper">
          <Navbar />
          <div className="main-panel" onClick={() => closeOffcanvas()}>
            <div className="content-wrapper">
              <Loader />
              <AppNavigator />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Loader />
        <AuthNavigator />
      </>
    );
  }
}

export default App;
