import jwtDecode from "jwt-decode";
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_TOKEN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  LOGOUT_SUCCESS,
  LOGIN_COUNT_RESET,
  RESET_PERMISSION,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE,
} from "../types";

const user = JSON.parse(sessionStorage.getItem("user"));

const initialState = user
  ? {
      isLoggedIn: true,
      user: user,
      failCount: 0,
      permissions: [],
    }
  : { isLoggedIn: false, user: null, profile: null, failCount: 0 };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_PROFILE:
      return { ...state, permissions: [] };
    case REGISTER_SUCCESS: {
      return { isLoggedIn: false, register: true };
    }
    case REGISTER_FAILURE: {
      return { ...state, isLoggedIn: false };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        failCount: 0,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        failCount: state.failCount + 1,
      };
    }
    case LOGIN_COUNT_RESET: {
      return { ...state, failCount: 0 };
    }
    case FETCH_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: payload.userInfo,
        permissions: [...payload.userInfo.role?.permissions],
      };
    }
    case FETCH_USER_PROFILE_FAILURE: {
      return { ...state };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return { ...state, isLoggedIn: false, user: null, navigate: true };
    }
    case FORGOT_PASSWORD_FAILURE: {
      return { ...state, isLoggedIn: false, user: null };
    }
    case RESET_PASSWORD_SUCCESS: {
      return { ...state, isLoggedIn: false, user: null, navigate: true };
    }
    case RESET_PASSWORD_FAILURE: {
      return { ...state, isLoggedIn: false, user: null };
    }

    case VERIFY_USER_SUCCESS: {
      return { ...state, isLoggedIn: false, user: null, navigate: true };
    }
    case VERIFY_USER_FAILURE: {
      return { ...state, isLoggedIn: false, user: null, navigate: true };
    }

    case LOGOUT_SUCCESS: {
      return { ...state, isLoggedIn: false, user: null };
    }

    case RESET_PERMISSION: {
      return {
        ...state,
        permissions: jwtDecode(state.user.accessToken).role.permissions,
      };
    }

    case REFRESH_TOKEN: {
      return {
        ...state,
        permissions: payload.decoded,
        user: {
          // ...user,
          accessToken: payload.accessToken,
        },
      };
    }
    default: {
      return state;
    }
  }
}
