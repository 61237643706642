import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchGSTMasterListAsync = async (id) => {
    try {
      const GSTMasterRef = collection(database, "gstMaster");

      const q = query(GSTMasterRef, where("createdBy", "==", String(id)));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
          dataArr.push(doc.data());
        });
        response = {
          status: 200,
          data: dataArr,
          message: t("message_gst_master_get_success"),
        };
      } else {
        response = {
          status: 400,
          message: t("message_gst_master_not_found"),
        };
      }

      return response;
    } catch (error) {
      let message = generateMessage(error.code);
      response = {
        status: 401,
        data: null,
        message: message || error.message,
      };
      return response;
    }
};
export const createGSTMasterAsync = async (payload) => {
  try {
    const GSTMasterRef = collection(database, "gstMaster");

    const q = query(
      GSTMasterRef,
      where("createdBy", "==", payload?.createdBy),
      where("gst", "==", payload.gst)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      await setDoc(doc(database, "gstMaster", Number(payload._id).toString()), {
        ...payload,
        createdAt: new Date(),
      });
      response = {
        status: 200,
        message: t("message_gst_master_new_successful"),
      };
    } else {
      response = {
        status: 401,
        message: t("message_gst_exists"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const updateGSTMasterAsync = async (payload, id) => {
  try {
    await updateDoc(doc(database, "gstMaster", String(id)), {
      ...payload,
      lastModified: new Date(),
    });
    response = {
      status: 200,
      message: t("message_gst_master_update_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchGSTMasterDetailAsync = async (id) => {
  try {
    const docRef = doc(database, "gstMaster", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      response = {
        status: 200,
        data: docSnap.data(),
      };
    } else {
      response = {
        status: 401,
        data: null,
        message: "GSTMaster details not found",
      };
    }

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
export const deleteGSTMasterAsync = async (id) => {
  try {
    const docRef = doc(database, "gstMaster", String(id));
    await deleteDoc(docRef);

    response = {
      status: 200,
      message: t("gst_master_deleted_succeful"),
    };

    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
