import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { verifyPermission } from "../App";
import { fetchUserProfile } from "../redux/auth/actions";
import { fetchAllMasters } from "../redux/common/actions";
import { showLoader } from "../redux/lem/action";

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.Auth);
  const profileState = useSelector((state) => state.Profile);

  const Navigate = useNavigate();
  const location = useLocation();
  const [dropdownPaths, setDropdownPaths] = useState([
    { path: "/masters", state: "mastersMenuOpen", isActive: false },
  ]);

  const isPathActive = (path) => {
    return location.pathname === path;
  };
  const isParentActive = (path) => {
    return location.pathname.startsWith(path);
  };
  const isSubMenuActive = (stateName) => {
    return dropdownPaths.find((item) => item.state === stateName).isActive;
  };

  const toggleMenuState = (stateName) => {
    const dropdowns = [...dropdownPaths];
    dropdowns.map((item) => {
      if (item.state === stateName) {
        item.isActive = !item.isActive;
      }
      return item;
    });
    //console.log("dropdowns", dropdowns);
    setDropdownPaths(dropdowns);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
    const uid = jwtDecode(authState.user.accessToken).user_id;
    dispatch(showLoader(t("message")));
    dispatch(fetchUserProfile(uid));
    dispatch(fetchAllMasters());
  }, []);

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  const onRouteChanged = () => {
    window.scrollTo(0, 0);
    document.querySelector("#sidebar").classList.remove("active");
    const dropdowns = [...dropdownPaths];
    dropdowns.map((item) => (item.isActive = isParentActive(item.path)));
    setDropdownPaths(dropdowns);
  };

  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          {profileState.profile?.profile.logo && (
            <a className="sidebar-brand brand-logo" href="index.html">
              <img
                src={`${profileState.profile?.profile.logo}`}
                alt="logo"
              />
            </a>
          )}
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            {/* <img
              src={require("../assets/images/axion_logo-mini.png")} //changed
              alt="logo"
            /> */}
          </a>
        </div>
        <ul className="nav mt-2">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  {/* {profileState.profile?.profile.logo ? (
                    <img
                      className="img-xs rounded-circle"
                      src={`./images/company_profile/${profileState.profile?.profile.logo}`}
                      alt="profile"
                    />
                  ) : ( */}
                  <div className="badge profile-badge img-xs rounded-circle badge-primary">
                    {authState.profile
                      ? `${authState.profile.firstName
                          .charAt(0)
                          .toUpperCase()}${authState.profile.lastName
                          .charAt(0)
                          .toUpperCase()}`
                      : ""}
                  </div>
                  {/* )} */}
                </div>
                <div className="profile-name">
                  <p className="mb-0 d-none d-sm-block navbar-profile-name">
                    {authState?.profile
                      ? `${authState.profile.firstName.toUpperCase()} ${authState.profile.lastName.toUpperCase()}`
                      : ""}
                  </p>
                  <span>
                    {authState?.profile
                      ? `${authState.profile.role?.roleName}`
                      : ""}
                  </span>
                </div>
              </div>
              <Dropdown>
                <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="!#"
                    className="dropdown-item preview-item"
                    onClick={(evt) => {
                      evt.preventDefault();
                      Navigate("/account-setting");
                    }}
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">
                        {t("account_settings")}
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="!#"
                    onClick={(evt) => {
                      evt.preventDefault();
                      Navigate("/change-password");
                    }}
                    className="preview-item"
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">
                        {t("change_password")}
                      </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">
              <h4>{t("menu")}</h4>
            </span>
          </li>
          {verifyPermission("UPDATE_COMPANY_PROFILE") && (
            <li
              className={
                isPathActive("/company-profile")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/company-profile">
                <span className="menu-icon">
                  <i className="mdi mdi-home-modern"></i>
                </span>
                <span className="menu-title">{t("title_company_profile")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_DASHBOARD") && (
            <li
              className={
                isPathActive("/dashboard")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/dashboard">
                <span className="menu-icon">
                  <i className="mdi mdi-speedometer"></i>
                </span>
                <span className="menu-title">{t("title_dashboard")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_CUSTOMER") && (
            <li
              className={
                isPathActive("/customer") ||
                isPathActive("/customer-form") ||
                isPathActive(
                  `/customer-form/${location.pathname.split("/")[2]}`
                )
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/customer">
                <span className="menu-icon">
                  <i className="mdi mdi-account-check"></i>
                </span>
                <span className="menu-title">{t("customer")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_QUOTATION") && (
            <li
              className={
                isPathActive("/quotation") ||
                isPathActive("/quotation-form") ||
                isPathActive(
                  `/quotation-form/${location.pathname.split("/")[2]}`
                )
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/quotation">
                <span className="menu-icon">
                  <i className="mdi mdi-book"></i>
                </span>
                <span className="menu-title">{t("quotation")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_INVOICE") && (
            <li
              className={
                isPathActive("/invoice") ||
                isPathActive("/invoice-form") ||
                isPathActive(`/invoice-form/${location.pathname.split("/")[2]}`)
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/invoice">
                <span className="menu-icon">
                  <i className="mdi mdi-currency-inr"></i>
                </span>
                <span className="menu-title">{t("invoice")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_ROLE") && (
            <li
              className={
                isPathActive("/roles_and_rights") ||
                isPathActive("/roles_and_rights-form") ||
                isPathActive(
                  `/roles_and_rights-form/${location.pathname.split("/")[2]}`
                )
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/roles_and_rights">
                <span className="menu-icon">
                  <i className="mdi mdi-security"></i>
                </span>
                <span className="menu-title">{t("roles_and_rights")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_USER_MANAGEMENT") && (
            <li
              className={
                isPathActive("/user") ||
                isPathActive("/user-form") ||
                isPathActive(`/user-form/${location.pathname.split("/")[2]}`)
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/user">
                <span className="menu-icon">
                  <i className="mdi mdi-sitemap"></i>
                </span>
                <span className="menu-title">{t("user_management")}</span>
              </Link>
            </li>
          )}

          {/* master menu dropdown here*/}
          {verifyPermission("READ_MASTERS")&&<li
            className={
              isParentActive("/masters")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                isSubMenuActive("mastersMenuOpen")
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("mastersMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-memory"></i>
              </span>
              <span className="menu-title">{t("masters")}</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={isSubMenuActive("mastersMenuOpen")}>
              <div>
                <ul className="nav flex-column sub-menu">
                  {/* {commonState.allMasters.map((item) => (
                      <li key={item._id} className="nav-item">
                        <Link
                          className={
                            isPathActive(`/masters/${item.slug}`)
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={`/masters/${item.slug}`}
                        >
                          {t(`master_${item.slug}`)}
                        </Link>
                      </li>
                    ))} */}

                  {/* <li className="nav-item">
                    <Link
                      className={
                        isPathActive(`/masters/tax_master`) ||
                        isPathActive(`/masters/tax_master-form`) ||
                        isPathActive(
                          `/masters/tax_master-form/${
                            location.pathname.split("/")[3]
                          }`
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/masters/tax_master`}
                    >
                      {t(`tax_master`)}
                    </Link>
                  </li> */}
                  {/* {commonState.allMasters
                    .filter((item) => item.isActive)
                    .map((item) => (
                      <li key={String(item._id)} className="nav-item">
                        <Link
                          className={
                            isPathActive(`/masters/${item.slug}`)
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={`/masters/${item.slug}`}
                        >
                          {t(`master_${item.slug}`)}
                        </Link>
                      </li>
                    ))} */}
                  {verifyPermission("READ_MASTER_OPTION") &&<li className="nav-item">
                    <Link
                      className={
                        isPathActive(`/masters/job_type`)
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/masters/job_type`}
                    >
                      {t(`job_type`)}
                    </Link>
                  </li>}
                  {verifyPermission("READ_MASTER_OPTION") &&<li className="nav-item">
                    <Link
                      className={
                        isPathActive(`/masters/recruitment_status`)
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/masters/recruitment_status`}
                    >
                      {t(`recruitment_status`)}
                    </Link>
                  </li>}
                  {verifyPermission("READ_MASTER_OPTION") &&<li className="nav-item">
                    <Link
                      className={
                        isPathActive(`/masters/employment_status`)
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/masters/employment_status`}
                    >
                      {t(`employment_status`)}
                    </Link>
                  </li>}
                  {verifyPermission("READ_MASTER_OPTION") &&<li className="nav-item">
                    <Link
                      className={
                        isPathActive(`/masters/gst_master`) ||
                        isPathActive(`/masters/gst_master-form`) ||
                        isPathActive(
                          `/masters/gst_master-form/${
                            location.pathname.split("/")[3]
                          }`
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/masters/gst_master`}
                    >
                      {t(`gst_master`)}
                    </Link>
                  </li>}
                  {verifyPermission("READ_MASTER_OPTION") &&<li className="nav-item">
                    <Link
                      className={
                        isPathActive(`/masters/designation`)
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/masters/designation`}
                    >
                      {t(`designation`)}
                    </Link>
                  </li>}
                  {verifyPermission("READ_MASTER_OPTION") &&<li className="nav-item">
                    <Link
                      className={
                        isPathActive(`/masters/currency`)
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/masters/currency`}
                    >
                      {t(`currency`)}
                    </Link>
                  </li>}
                </ul>
              </div>
            </Collapse>
          </li>}

          {verifyPermission("READ_CODE_SETUP") && (
            <li
              className={
                isPathActive("/code-setup") ||
                isPathActive("/code-setup-form") ||
                isPathActive(
                  `/code-setup-form/${location.pathname.split("/")[2]}`
                )
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/code-setup">
                <span className="menu-icon">
                  <i className="mdi mdi-wrap"></i>
                </span>
                <span className="menu-title">{t("code_setup")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_REPORT") && (
            <li
              className={
                isPathActive("/reports") || isPathActive("/reports-form")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/reports">
                <span className="menu-icon">
                  <i className="mdi mdi-library-books"></i>
                </span>
                <span className="menu-title">{t("reports")}</span>
              </Link>
            </li>
          )}
          {verifyPermission("READ_HR_DEPARTMENT") && (
            <>
              <li className="nav-item nav-category">
                <span className="nav-link">HR Department</span>
              </li>
              <li
                  className={
                    isPathActive("/holiday-setup")
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link className="nav-link" to="/holiday-setup">
                    <span className="menu-icon">
                      <i className="mdi mdi-file-pdf"></i>
                    </span>
                    <span className="menu-title">{t("holiday_setup")}</span>
                  </Link>
                </li>
              {verifyPermission("READ_RECRUIT") && (
                <li
                  className={
                    isPathActive("/recruit") ||
                    isPathActive("/recruit-form") ||
                    isPathActive(
                      `/recruit-form/${location.pathname.split("/")[2]}`
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link className="nav-link" to="/recruit">
                    <span className="menu-icon">
                      <i className="mdi mdi-account-search"></i>
                    </span>
                    <span className="menu-title">{t("recruit")}</span>
                  </Link>
                </li>
              )}
              {verifyPermission("READ_EMPLOYEE") && (
                <li
                  className={
                    isPathActive("/employ_onboarding") ||
                    isPathActive("/employ_onboarding-form") ||
                    isPathActive(
                      `/employ_onboarding-form/${
                        location.pathname.split("/")[2]
                      }`
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link className="nav-link" to="/employ_onboarding">
                    <span className="menu-icon">
                      <i className="mdi mdi-human-greeting"></i>
                    </span>
                    <span className="menu-title">{t("employ_onboarding")}</span>
                  </Link>
                </li>
              )}
              <li
                  className={
                    isPathActive("/employee_attendance")
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link className="nav-link" to="/employee_attendance">
                    <span className="menu-icon">
                      <i className="mdi mdi-calendar-clock"></i>
                    </span>
                    <span className="menu-title">{t("employee_attendance")}</span>
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/attendance_report")
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link className="nav-link" to="/attendance_report">
                    <span className="menu-icon">
                      <i className="mdi mdi-file-pdf"></i>
                    </span>
                    <span className="menu-title">{t("attendance_report")}</span>
                  </Link>
                </li>
            </>
          )}

          {verifyPermission("READ_PROJECT_MANAGEMENT") && (
            <>
              <li className="nav-item nav-category">
                <span className="nav-link">Project Management</span>
              </li>
              {verifyPermission("READ_PROJECT") && (
                <li
                  className={
                    isPathActive("/project") ||
                    isPathActive("/project-form") ||
                    isPathActive(
                      `/project-form/${location.pathname.split("/")[2]}`
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link className="nav-link" to="/project">
                    <span className="menu-icon">
                      <i className="mdi mdi-folder-plus"></i>
                    </span>
                    <span className="menu-title">{t("project")}</span>
                  </Link>
                </li>
              )}
              {verifyPermission("READ_TICKET") && (
                <li
                  className={
                    isPathActive("/tickets") ||
                    isPathActive("/tickets-form") ||
                    isPathActive(
                      `/tickets-form/${location.pathname.split("/")[2]}`
                    )
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link className="nav-link" to="/tickets">
                    <span className="menu-icon">
                      <i className="fa fa-ticket"></i>
                    </span>
                    <span className="menu-title">{t("ticket_management")}</span>
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
